import { useSelector } from "react-redux";

import { handleExpand } from "./event-handlers";

import MediaThumbnail from "../MediaThumbnail/MediaThumbnail";

import "./MediaPreview.css";

function MediaPreview() {
  const messagesImage = useSelector((state) => state.session.messagesImage);

  let firstThree;

  if (messagesImage) {
    const [first, second, third] = messagesImage;
    firstThree = [first, second, third];
  }

  return (
    <div className="preview_container">
      <div className="preview_header">
        <h5>Media, links and docs</h5>

        <h4>{messagesImage && messagesImage.length}</h4>
        <i
          className="fa-solid fa-caret-right cursor_pointer"
          onClick={handleExpand}
        ></i>
      </div>

      <div className="preview_img">
        {messagesImage
          ? firstThree.map((message) =>
              message ? (
                <article key={Math.random()}>
                  <MediaThumbnail message={message} />
                </article>
              ) : null
            )
          : null}
      </div>
    </div>
  );
}

export default MediaPreview;
