import "./CTA.css";

import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";

import { handleMuteStatusChange } from "../../../Store/services/async-thunks";
import { switchClickHandler } from "./cta-event-handlers";

import desappearing from "../../../Assets/desappearing.png";

function CTAelement() {
  const isMuted = useSelector((state) => state.chats.selectedChat.isMuted);
  const loggedInUserID = useSelector((state) => state.userLogin.userData._id);
  const canSendMuteRequest = useSelector(
    (state) => state.chats.canSendMuteRequest
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    canSendMuteRequest
      ? dispatch(handleMuteStatusChange(loggedInUserID))
      : null;
  }, [canSendMuteRequest, dispatch, loggedInUserID]);

  return (
    <>
      <div className="cta_container">
        <div className="cta_container cta_options">
          <i className="fa-solid fa-star"></i>
          <div className="cta_options_content">
            <h5>Starred Messages</h5>
          </div>
          <i className="fa-solid fa-caret-right"></i>
        </div>
        <div className="cta_descriptions mt_30">Archived chats are muted</div>

        <div className="cta_container cta_options">
          <i className="fa-solid fa-bell"></i>
          <div className="cta_options_content">
            <h5>Mute Notifications</h5>
          </div>

          <label className="switch">
            <input type="checkbox" checked={isMuted} />
            <span className="slider round" onClick={switchClickHandler}></span>
          </label>
        </div>

        <div className="cta_container cta_options">
          <img src={desappearing} alt="desappearing" />
          <div className="cta_options_content">
            <h5>Disappearing messages</h5>
          </div>
          <i className="fa-solid fa-caret-right"></i>
        </div>

        <div className="cta_container cta_options">
          <i className="fa-solid fa-lock"></i>
          <div className="cta_options_content">
            <h5>Encryption</h5>
            <p className=" cta_descriptions mt_10">
              Messages are ent-to-end encrypted. Click to verify.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CTAelement;
