import { handleGroupFilter } from "../event-handlers";

function SearchBar({ setTypedInput, placeholder }) {
  return (
    <input
      type="text"
      placeholder={placeholder}
      className="fit_content"
      onChange={(e) => {
        handleGroupFilter(e);
        setTypedInput(e.target.value);
      }}
    />
  );
}

export default SearchBar;
