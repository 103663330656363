import { useSelector } from "react-redux";

import User from "../User/User";

import { selectUserInGroup } from "../event-handlers";

function UsersList({ typedInput }) {
  const filteredUsersGroup = useSelector(
    (state) => state.ui.filteredUsersGroup
  );

  const filterUsersGroupOnSelect = useSelector(
    (state) => state.ui.filterUsersGroupOnSelect
  );

  const selectedUserFromGroup = useSelector(
    (state) => state.ui.selectedUserFromGroup
  );

  let usersArray;

  if (filterUsersGroupOnSelect.length || selectedUserFromGroup.length)
    usersArray = filterUsersGroupOnSelect;
  else usersArray = filteredUsersGroup;

  return (
    <>
      {usersArray.map(({ type, usersWithInitial }) => (
        <section key={type}>
          <header className="pt_16">
            <h4 className="ng_font_common fw_500">{type}</h4>
          </header>
          <ul className="ng_contact_list">
            {usersWithInitial.map(({ name, statusMessage, avatar, _id }) => {
              return (
                <li
                  key={_id}
                  className="ng_contact_item"
                  onClick={selectUserInGroup.bind(null, _id, typedInput)}
                >
                  <User user={{ name, avatar, statusMessage, _id }} />
                </li>
              );
            })}
          </ul>
        </section>
      ))}
    </>
  );
}

export default UsersList;
