import "./ChatscreenPlaceholder.css";

import whatsAppWeb from "../../Assets/whatsapp_web.png";

function ChatscreenPlaceholder() {
  return (
    <div className="placeholder_container">
      <div></div>
      <div className="placeholder_main">
        <img src={whatsAppWeb} alt="whatsapp web" />

        <h5>Chat Concept</h5>
        <p>
          Send and receive messages without keeping your phone online. Use Chat
          Concept anywhere from web directly.
        </p>
      </div>

      <div className="end-to-end">
        <i className="fa-solid fa-lock"></i>

        <h5>End-to-end encrypted</h5>
      </div>
    </div>
  );
}

export default ChatscreenPlaceholder;
