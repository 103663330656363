import SidebarHeader from "./SidebarHeader/SidebarHeader";
import SidebarSearch from "./SidebarSearch/SidebarSearch";
import SidebarChats from "./SidebarChats/SidebarChats";
import { useEffect } from "react";
import { checkEllipsesClick } from "../../helper_functions/event-handlers";

function Sidebar() {
  // options dialog for sidebar
  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", checkEllipsesClick);

    return () => body.removeEventListener("click", checkEllipsesClick);
  }, []);

  return (
    <>
      <SidebarHeader />

      <SidebarSearch />

      <SidebarChats />
    </>
  );
}

export default Sidebar;
