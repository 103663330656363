import {
  clearSelections,
  filterUsers,
  showBackdrop,
  showForwardModal,
} from "../../../Store/slices/ui-slice";

import { store } from "../../../Store/store/store";

const { dispatch, getState } = store;

export function closeForward() {
  dispatch(clearSelections());
  dispatch(showBackdrop(false));
  dispatch(showForwardModal(false));
}

export function setUsers(searchFor) {
  const otherUsers = getState().chats.otherUsers;

  const paylaod = { searchFor, otherUsers };

  dispatch(filterUsers(paylaod));
}

export function initiateFilter(event) {
  const searchFor = event.target.value;

  setUsers(searchFor);
}

export function handleForwardInputChange(event) {
  initiateFilter(event);
}
