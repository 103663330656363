import "../Sidebar.css";

import storyStatus from "../../../Assets/story-status.png";
import { useSelector } from "react-redux";
import SidebarHeaderPopup from "../../Popups/SidebarHeaderPopup/SidebarHeaderPopup";
import { menuItems } from "../../../constants/consts";

function SidebarHeader() {
  const userData = useSelector((state) => state.userLogin.userData);
  const showSidebarHeaderPopup = useSelector(
    (state) => state.ui.showSidebarHeaderPopup
  );

  return (
    <div className="left_header">
      {showSidebarHeaderPopup ? (
        <SidebarHeaderPopup menuItems={menuItems} />
      ) : null}
      {userData.avatar ? (
        <img src={userData.avatar} alt="user profile" />
      ) : null}
      <div className=" left_header sidebar_header_features">
        <i className="fa-solid fa-users"></i>
        <img src={storyStatus} alt="story_status" />
        <i className="fa-regular fa-comment"></i>
        <i className="fa-solid fa-ellipsis fa-rotate-90" id="ellipses"></i>
      </div>
    </div>
  );
}

export default SidebarHeader;
