import { configureStore } from "@reduxjs/toolkit";
import userLoginReducer from "../slices/user-login";
import sessionReducer from "../slices/chats-slice";
import allChatsReducer from "../slices/allChats-slice";
import messagesReducer from "../slices/messages-slice";
import uiReducer from "../slices/ui-slice";

export const store = configureStore({
  reducer: {
    userLogin: userLoginReducer,
    session: sessionReducer,
    chats: allChatsReducer,
    messages: messagesReducer,
    ui: uiReducer,
  },
});
