import {
  displayNewGroup,
  displaySidebarHeaderPopup,
  filterUsersWithInitials,
  usersFilterGroup,
} from "../../../Store/slices/ui-slice";
import { store } from "../../../Store/store/store";

const { getState, dispatch } = store;

export function filterUsersByInitials() {
  dispatch(displaySidebarHeaderPopup(false));

  let { otherUsers } = getState().chats;
  dispatch(filterUsersWithInitials(otherUsers));
  dispatch(usersFilterGroup("")); // initially matches all for showing all users
  dispatch(displayNewGroup(true));
}
