import { useSelector } from "react-redux";
import { createPortal } from "react-dom";

import "./Backdrop.css";

function Backdrop(props) {
  const displayBackdrop = useSelector((state) => state.ui.displayBackdrop);

  return displayBackdrop
    ? createPortal(
        <span id="backdrop">{props.children}</span>,
        document.querySelector("body")
      )
    : null;
}

export default Backdrop;
