import { useContext } from "react";
import { useSelector } from "react-redux";

import { socketContext } from "../../../Contexts/socket-context/socket-context";
import { handleForwardingMessages } from "./event-handlers";

import "./ForwardFooter.css";

import sendButton from "../../../../Assets/Send.png";

function ForwardFooter() {
  const { showForwardFooter, selectedUsernames } = useSelector(
    (state) => state.ui
  );

  const { socket } = useContext(socketContext);

  return showForwardFooter ? (
    <footer className="forward_selected_contacts forward_green">
      <p className="forward_font_bold">{selectedUsernames}</p>
      <button
        type="button"
        className="forward_selected_btn"
        onClick={handleForwardingMessages.bind(null, socket)}
      >
        <img
          src={sendButton}
          alt="Send messages to selected"
          className="fit_parent"
        />
      </button>
    </footer>
  ) : null;
}

export default ForwardFooter;
