// TODO: put files in correct folders.

import { store } from "../../../../../Store/store/store";

import {
  selections,
  setSelectedUsernames,
  setSelectedUsersNames,
} from "../../../../../Store/slices/ui-slice";

const { dispatch } = store;

export function handleSelectionChange(param) {
  return function (dispatch, getState) {
    const { otherUsers } = getState().chats;

    const { user, checked } = param;

    const payload = {
      filterArr: otherUsers,
      id: user._id,
      checked,
      type: "users",
    };

    dispatch(selections(payload));
    dispatch(setSelectedUsernames());
    dispatch(setSelectedUsersNames());
  };
}

export function handleInputChange(event, props) {
  const payload = { user: props.user, checked: event.target.checked };
  dispatch(handleSelectionChange(payload));
}

export function setSelectedMessages(message, checked) {
  return function (dispatch, getState) {
    const { messagesLink, messagesDocument, messagesImage } =
      getState().session;

    const states = {
      link: messagesLink,
      document: messagesDocument,
      image: messagesImage,
    };

    const selectedState = states[message.messageType];

    const paylaod = {
      filterArr: selectedState,
      id: message._id,
      checked,
      type: "messages",
    };

    dispatch(selections(paylaod));
  };
}

export function isPresent(searchIn, searchFor) {
  const [item] = searchIn.filter((el) => el === searchFor);
  return item;
}
