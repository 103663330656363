/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { extractTime } from "../../helper_functions/helpers";
import {
  replaceArrayIndexes,
  transformChat,
} from "../slices-helper-functions/slice-helper-functions";

const initialAllChatsState = {
  isInitialStartup: true,
  chats: [],
  filteredChats: [],
  users: [],
  loggedInUser: {},
  otherUsers: [],
  selectedChat: {},
  prevSwitchTimestamp: "",
  canSendMuteRequest: false,
  chatAvail: false,
  selectedChatAvail: false,
};

const allChatsSlice = createSlice({
  initialState: initialAllChatsState,
  name: "chats",
  reducers: {
    transform(state, action) {
      const { chats, loggedInUserID } = action.payload;

      const transformedChats = chats.map((chat) =>
        transformChat(state, chat, loggedInUserID)
      );

      state.chats = transformedChats;
      state.filteredChats = transformedChats;
      state.chatAvail = true;
    },

    handleSidebarInputChange(state, action) {
      const inputValue = action.payload;

      state.filteredChats = state.chats.filter(({ chatName }) =>
        chatName.toLowerCase().includes(inputValue.toLowerCase())
      );
    },

    removeChatscreenPlaceholder(state) {
      state.isInitialStartup = false;
    },

    setSelectedChat(state, action) {
      const selectedChatID = action.payload;

      state.selectedChat = state.chats.filter(
        ({ chatID }) => chatID === selectedChatID
      )[0];

      state.selectedChatAvail = true;
    },

    receiveMessageSocket(state, action) {
      // process will be repeated for every received message.
      const {
        content,
        createdAt,
        messageStatus,
        chat,
        unreadCount,
        loggedInUserID,
      } = action.payload;

      const indexOfSelectedChat = state.chats.findIndex(
        ({ chatID }) => chatID === chat
      );

      const [{ count }] = unreadCount.filter(
        ({ user }) => user === loggedInUserID
      );

      const sentTime = extractTime(createdAt);

      state.chats[indexOfSelectedChat].unreadCount = count;
      state.chats[indexOfSelectedChat].time = sentTime;
      state.chats[indexOfSelectedChat].latestMessage = content;
      state.chats[indexOfSelectedChat].latestMessageStatus = messageStatus;

      state.filteredChats[indexOfSelectedChat].unreadCount = count;
      state.filteredChats[indexOfSelectedChat].time = sentTime;
      state.filteredChats[indexOfSelectedChat].latestMessage = content;
      state.filteredChats[indexOfSelectedChat].latestMessageStatus =
        messageStatus;
    },

    changeMuteStatus(state, action) {
      const { chat, loggedInUserID } = action.payload;

      const chatID = chat._id;

      state.selectedChat = chat;

      const transformedChat = transformChat(state, chat, loggedInUserID);

      state.selectedChat = transformedChat;

      for (let index = 0; index < state.chats.length; index += 1) {
        if (state.chats[index].chatID === chatID) {
          state.chats[index] = transformedChat;
          break;
        }
      }

      for (let index = 0; index < state.filteredChats.length; index += 1) {
        if (state.filteredChats[index].chatID === chatID) {
          state.filteredChats[index] = transformedChat;
          break;
        }
      }
    },

    retreiveSwitchTimestamp(state, action) {
      const currTimestamp = action.payload;
      const prevTimestamp = state.prevSwitchTimestamp;

      currTimestamp - prevTimestamp > 1000
        ? (state.canSendMuteRequest = true)
        : (state.canSendMuteRequest = false);

      state.prevSwitchTimestamp = currTimestamp;
    },

    resetPrevTimestamp(state) {
      state.prevSwitchTimestamp = "";
    },

    resetMuteRequestStatus(state) {
      state.canSendMuteRequest = false;
    },

    onUpdateChat(state, action) {
      const { receivedChat, loggedInUserID } = action.payload;
      const { _id: receivedId } = receivedChat;

      const {
        selectedChat: { selectedChatID },
      } = state;

      const transformedChat = transformChat(
        state,
        receivedChat,
        loggedInUserID
      );

      replaceArrayIndexes({
        iterables: [state.chats, state.filteredChats],
        receivedId,
        transformedChat,
      });

      selectedChatID === receivedId
        ? (state.selectedChat = transformedChat)
        : null;
    },
  },
});

export const {
  transform,
  handleSidebarInputChange,
  removeChatscreenPlaceholder,
  setSelectedChat,
  receiveMessageSocket,
  changeMuteStatus,
  retreiveSwitchTimestamp,
  resetMuteRequestStatus,
  onUpdateChat,
} = allChatsSlice.actions;

export default allChatsSlice.reducer;
