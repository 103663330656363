import { useSelector } from "react-redux";
import { handleTabClick } from "./panel-event-handlers";

import Media from "../Media/Media";
import DocumentPreview from "../DocumentPreview/DocumentPreview";
import LinksPreview from "../LinkPreview/LinksPreview";
import MediaHeader from "../Media/MediaHeader/MediaHeader";

import "./TabPanel.css";

const Tabpanel = () => {
  const tabs = useSelector((state) => state.ui.tabs);
  const selectedTabName = useSelector((state) => state.ui.selectedTabName);
  const { media, docs, links } = useSelector((state) => state.ui.selectedTab);

  return (
    <>
      <MediaHeader />

      <div className="panel_main">
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`panel_box ${
              selectedTabName === tab ? "panel_box_selected" : ""
            }`}
            onClick={() => handleTabClick(tab)}
          >
            <h5>{tab}</h5>
          </div>
        ))}
      </div>

      {media ? <Media /> : null}
      {docs ? <DocumentPreview /> : null}
      {links ? <LinksPreview /> : null}
    </>
  );
};
export default Tabpanel;
