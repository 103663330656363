import { closeGroupDetails, closeNewGroup } from "../event-handlers";

import goBack from "../../../../Assets/mh_back.png";

function GroupHeader({ heading, isGroupDetailsOpened }) {
  const clickBackHandler = isGroupDetailsOpened
    ? closeGroupDetails
    : closeNewGroup;

  return (
    <>
      <button className="ng_back" onClick={clickBackHandler}>
        <img src={goBack} alt="Go back" className="fit_content" />
      </button>
      <h4 className="ng_font_common fw_500">{heading}</h4>
    </>
  );
}

export default GroupHeader;
