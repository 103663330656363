import "./InfoHeader.css";

import info_close from "../../../Assets/info_close.png";

import { showInfoSidebar } from "../../../helper_functions/event-handlers";

function InfoHeader() {
  return (
    <section className="info_container">
      <img
        src={info_close}
        alt="Close user information sidebar"
        onClick={() => showInfoSidebar(false)}
      />
      <h5>Contact Info</h5>
    </section>
  );
}

export default InfoHeader;
