import "./Pill.css";

import cancelFilled from "../../../../Assets/CancelFilled.svg";

function Pill(props) {
  const { name, avatar, clickHandler } = props.pill;

  return (
    <>
      <article className="chip_container">
        <div className="chip_img">
          <img src={avatar} alt="avatar" className="fit_content" />
        </div>
        <div className="chip_name">{name}</div>
        <div className="chip_cancel" onClick={clickHandler}>
          <img src={cancelFilled} alt="close" />
        </div>
      </article>
    </>
  );
}

export default Pill;
