import { showBackdrop, showForwardModal, showTabPanel } from "../../../../Store/slices/ui-slice";
import { store } from "../../../../Store/store/store";

const { dispatch } = store;

export function hideTabPanel() {
  dispatch(showTabPanel(false));
}

export function handleForwardingClick() {
  dispatch(showBackdrop(true));
  dispatch(showForwardModal(true));
}
