/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
import { extractTime } from "../../helper_functions/helpers";

// TODO: sub-optimal

export function transformChat(state, chat, loggedInUserID) {
  let chatName;
  let chatAvatar;
  let isRead;
  let isGroupChat;

  const chatID = chat._id;

  const stateUsers = state.users;

  // extract users with {id: _, name: _}
  // to check which user has sent latest message
  if (!stateUsers.length) {
    chat.users.forEach((user) => stateUsers.push({ ...user, chatID }));
  } else {
    for (let user of chat.users) {
      let toPush = true;

      for (let stateUser of stateUsers) {
        if (stateUser._id.includes(user._id)) {
          toPush = false;
          break;
        }
      }

      if (toPush) stateUsers.push({ ...user, chatID });
    }
  }

  // chatName, chatAvatar and are messages read or not differs on
  // whether chat is groupchat or single
  if (!chat.isGroupChat) {
    isGroupChat = false;
    // user isn't loggedInUser means it's other user.
    const chatWith = chat.users.filter(({ _id }) => _id !== loggedInUserID);
    chatName = chatWith[0].name;
    chatAvatar = chatWith[0].avatar;

    // other users count > 0 -> means latest message is unread
    // and vice versa

    const [{ count }] = chat.unreadCount.filter(
      ({ user }) => user !== loggedInUserID
    );

    if (count > 0) {
      isRead = false;
    } else {
      isRead = true;
    }
  } else {
    isGroupChat = true;
    chatName = chat.chatName;
    chatAvatar = chat.avatar;

    // if all users haven't read a message it is unread.
    for (let el of chat.unreadCount) {
      if (el.count > 0) {
        isRead = false;
        break;
      } else {
        isRead = true;
      }
    }
  }

  let latestMessageSender;
  let latestMessage;
  let latestMessageStatus;
  let time;
  let showSidebarMessageStatus;

  if (chat.latestMessage) {
    latestMessageSender = chat.latestMessage.sender;
    latestMessage = chat.latestMessage.content;
    latestMessageStatus = chat.latestMessage.messageStatus;
    time = extractTime(chat.latestMessage.updatedAt);
    showSidebarMessageStatus = latestMessageSender === loggedInUserID;
  } else {
    latestMessageSender = "";
    latestMessage = "";
    latestMessageStatus = "";
    time = "";
    showSidebarMessageStatus = false;
  }

  const mutedBy = chat.mutedBy.filter(({ _id }) => _id === loggedInUserID);

  let isMuted = mutedBy.length > 0;

  const pinnedBy = chat.pinnedBy;

  // extract unreadCount
  const loggedInUserIndex = chat.unreadCount.findIndex(
    ({ user }) => user === loggedInUserID
  );
  const unreadCount = chat.unreadCount[loggedInUserIndex].count;

  let otherUsers = [];
  let loggedInUser;

  chat.users.forEach((user) =>
    user._id === loggedInUserID ? (loggedInUser = user) : otherUsers.push(user)
  );

  chat.users.forEach((user) => {
    if (isGroupChat) return;

    if (!(user._id === loggedInUserID)) {
      const index = state.otherUsers.findIndex(
        (otherUser) => otherUser._id === user._id
      );
      index < 0 ? state.otherUsers.push({ ...user, chatID }) : null;
    } else {
      state.loggedInUser = user;
    }
  });

  const showEmailInContactInfo = !isGroupChat;

  const showStatusInContactInfo = !isGroupChat;

  return {
    mutedBy,
    isMuted,
    time,
    isGroupChat,
    latestMessage,
    latestMessageSender,
    unreadCount,
    isRead,
    chatName,
    users: chat.users,
    chatAvatar,
    pinnedBy,
    latestMessageStatus,
    chatID,
    showSidebarMessageStatus,
    otherUsers,
    loggedInUser,
    showEmailInContactInfo,
    showStatusInContactInfo,
    admin: chat.admin,
  };
}

export function replaceArrayIndexes(arguements) {
  const { iterables, receivedId, transformedChat } = arguements;

  iterables.forEach((iterable) => {
    const changeIndex = iterable.findIndex((el) => el.chatID === receivedId);

    iterable[changeIndex] = transformedChat;
  });
}

export function messageStatusController(message, messageStatus) {
  const notRead = message.messageStatus !== "read";
  const different = message.messageStatus !== messageStatus;
  if (notRead && different) {
    message.messageStatus = messageStatus;
  }
}

export function extractMonthYear(message) {
  const creation = message.dateTag;

  // eslint-disable-next-line no-unused-vars
  let [date, month, year] = creation.split("/");

  year = +year;
  month = +month;

  year = year < new Date().getFullYear() ? year : 0;

  if (new Date().getMonth() + 1 === month) {
    month = "This Month";
  } else {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    month = months[month - 1];
  }

  return year ? month + " " + year : month;
}

export function handleCheckboxSelections(param) {
  const { id } = param;

  function matchIndex(el) {
    if (el._id === id) return el;
    else return null;
  }

  const { filterArr, state, checked } = param;

  if (checked) {
    const [matchedItem] = filterArr.filter(matchIndex);
    state.push(matchedItem);
  } else {
    const remove = state.findIndex(matchIndex);
    state.splice(remove, 1);
  }
}
