import {
  addSelectedUser,
  clearSelectedUsers,
  closePill,
  displayGroupDetails,
  displayNewGroup,
  usersFilterGroup,
} from "../../../Store/slices/ui-slice";

import { store } from "../../../Store/store/store";

const { dispatch } = store;

export function closeNewGroup() {
  dispatch(displayNewGroup(false));
  dispatch(clearSelectedUsers());
}

export function closeGroupDetails() {
  dispatch(displayNewGroup(true));
  dispatch(displayGroupDetails(false));
}

export function pillCloseHandler(_id, typedInput) {
  dispatch(closePill(_id));
  dispatch(usersFilterGroup(typedInput));
}

export function handleGroupFilter(event) {
  dispatch(usersFilterGroup(event.target.value));
}

export function selectUserInGroup(id, typedInput) {
  dispatch(addSelectedUser(id));
  dispatch(usersFilterGroup(typedInput));
}
