import { useSelector } from "react-redux";

import Pill from "../Pill/Pill";

import { pillCloseHandler } from "../event-handlers";

function Pills({ typedInput }) {
  const selectedUserFromGroup = useSelector(
    (state) => state.ui.selectedUserFromGroup
  );

  return (
    <>
      {selectedUserFromGroup.map(({ name, avatar, _id }) => {
        const pillProp = {
          name,
          avatar,
          clickHandler: pillCloseHandler.bind(null, _id, typedInput),
        };

        return (
          <div key={_id}>
            <Pill pill={pillProp} />
          </div>
        );
      })}
    </>
  );
}

export default Pills;
