// TODO: shift these to .env before shipping.

export const baseURL = process.env.REACT_APP_BASE_URL;
export const baseSocketURL = process.env.REACT_APP_BASESOCKET_URL;

const userEndpoints = {
  register: "/user/register",
  login: "/user/login",
  // query params ?
  search: "/user?search",
  edit: "/user/edit",
  removeAvatar: "/user/edit",
};

const chatsEndpoints = {
  getAllChats: "/chat",
  pinChat: "/chat/pin",
  unpinChat: "/chat/pin",
  archive: "/chat/archive",
  unarchive: "/chat/archive",
  getAllPinnedChats: "/chat/pin",
  getAllArchivedChats: "/chat/archive",
  deleteChat: "/chat",
  "mute/unMute": "/chat/mute",
};

const groupEndpoints = {
  create: "/group/create",
  changeGroupImage: "/group/photo",
  removeGroupImage: "/group/photo",
  changeGroupName: "/group/name",
  addGroupMember: "/group/member",
  removeGroupMember: "/group/member",
  leaveGroup: "/group/leave",
};

const messageEndpoints = {
  getAllMessages: "/message/",
  // dynamic /:chatId
  getAllStarredMessages: "/message/star",
  starMessage: "/message/star",
  unstarMessage: "/message/star",
  sendMessage: "/message",
};

export const endpoints = {
  userEndpoints,
  chatsEndpoints,
  groupEndpoints,
  messageEndpoints,
};
