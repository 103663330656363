/* eslint-disable no-unused-expressions */
import {
  INITIAL_GROUP_DETAILS,
  INITIAL_GROUP_SELECTION,
  INITIAL_SELECTION,
  INIT_UI_STATE,
} from "../../constants/redux";

import {
  getUniqueInitials,
  getUsersWithInitials,
} from "../../helper_functions/helpers";
import { handleCheckboxSelections } from "../slices-helper-functions/slice-helper-functions";

const { createSlice } = require("@reduxjs/toolkit");

const uiSlice = createSlice({
  name: "ui",
  initialState: INIT_UI_STATE,
  reducers: {
    sidebarDisplayStatus(state, action) {
      state.displayInfoSidebar = action.payload;
    },

    setTabPanel(state, action) {
      const tabClicked = action.payload;
      state.selectedTabName = tabClicked;

      const { selectedTab } = state;

      selectedTab.media = tabClicked === state.tabs[0];
      selectedTab.docs = tabClicked === state.tabs[1];
      selectedTab.links = tabClicked === state.tabs[2];
    },

    displaySidebarHeaderPopup(state, action) {
      state.showSidebarHeaderPopup = action.payload;
    },

    showSidebarActions(state, action) {
      state.showSidebarActions = action.payload;
    },

    showBackdrop(state, action) {
      state.displayBackdrop = action.payload;
    },

    showForwardModal(state, action) {
      state.showForwardModal = action.payload;
    },

    filterUsers(state, action) {
      const { searchFor, otherUsers } = action.payload;

      const filteredUsers = otherUsers.filter((user) =>
        user.name.toLowerCase().includes(searchFor.toLowerCase())
      );

      state.filteredUsers = filteredUsers;
    },

    selections(state, action) {
      const { type } = action.payload;

      const selectedUsers = state.selectedUsers;
      const selectedMessages = state.selectedMessages;

      let stateToChange;
      type === "users"
        ? (stateToChange = selectedUsers)
        : (stateToChange = selectedMessages);

      const checkboxSelectionArg = {
        ...action.payload,
        state: stateToChange,
      };

      handleCheckboxSelections(checkboxSelectionArg);

      !selectedUsers.length
        ? (state.showForwardFooter = false)
        : (state.showForwardFooter = true);
    },

    clearSelections(state) {
      return { ...state, ...INITIAL_SELECTION };
    },

    setSelectedUsersNames(state) {
      state.selectedUsersNames = state.selectedUsers.map((user) => user.name);
    },

    setSelectedUsernames(state) {
      state.selectedUsernames = state.selectedUsers
        .map((user) => user.name)
        .join(", ");
    },

    showTabPanel(state, action) {
      state.showTabPanel = action.payload;
    },

    filterUsersWithInitials(state, action) {
      if (state.filterUsersWithInitials.length) return state;

      const otherUsers = action.payload;

      let initials = getUniqueInitials(otherUsers);

      state.filterUsersWithInitials = getUsersWithInitials(
        initials,
        otherUsers
      );
    },

    usersFilterGroup(state, action) {
      const typedInput = action.payload.toLowerCase();

      const {
        selectedUserFromGroup,
        filterUsersWithInitials,
        filteredUsersGroup,
      } = state;

      let searchInArray;

      if (!selectedUserFromGroup.length) {
        searchInArray = filterUsersWithInitials;
        state.filteredUsersGroup = [];
      } else {
        searchInArray = filteredUsersGroup;
      }

      let tempArray = [];

      searchInArray.forEach(({ usersWithInitial }) => {
        usersWithInitial.forEach((user) => {
          user.name.toLowerCase().includes(typedInput)
            ? tempArray.push(user)
            : null;
        });
      });

      let initials = getUniqueInitials(tempArray);

      let replaceWithState = getUsersWithInitials(initials, tempArray);

      const areUsersSelected = selectedUserFromGroup.length;

      if (areUsersSelected) {
        state.filterUsersGroupOnSelect = replaceWithState;
      }

      if (!areUsersSelected) {
        state.filterUsersGroupOnSelect = [];
        state.filteredUsersGroup = replaceWithState;
      }
    },

    displayNewGroup(state, action) {
      state.showNewGroup = action.payload;
    },

    addSelectedUser(state, action) {
      const userID = action.payload;

      const { filteredUsersGroup, filterUsersGroupOnSelect } = state;

      filteredUsersGroup.forEach((el) => {
        const { usersWithInitial } = el;

        usersWithInitial.forEach((user, index) => {
          const exactMatch = user._id === userID;

          if (exactMatch) {
            state.selectedUserFromGroup.push(user);
            usersWithInitial.splice(index, 1);
          }
        });
      });

      state.filteredUsersGroup = filteredUsersGroup.filter(
        ({ usersWithInitial }) => usersWithInitial.length
      );

      // selecting user removes user from state.
      filterUsersGroupOnSelect.forEach(({ usersWithInitial }, indexOut) => {
        usersWithInitial.forEach(({ _id }, indexIn) => {
          if (_id === userID) {
            state.filterUsersGroupOnSelect[indexOut].usersWithInitial.splice(
              indexIn,
              1
            );

            // by removing user usersWithInitial gets empty.
            if (!state.filterUsersGroupOnSelect[indexOut].length) {
              state.filterUsersGroupOnSelect.splice(indexOut, 1);
            }
            return;
          }
        });
      });
    },

    clearSelectedUsers(state) {
      state.selectedUserFromGroup = [];
    },

    closePill(state, action) {
      const idToRemove = action.payload;

      const { selectedUserFromGroup, filteredUsersGroup } = state;

      // on pill closing if no selected users are present =>  empty array
      const areUsersSelected = state.selectedUserFromGroup.length;
      if (!areUsersSelected) state.filterUsersGroupOnSelect = [];

      let userToAdd;
      selectedUserFromGroup.forEach((user, index) => {
        if (idToRemove === user._id) {
          [userToAdd] = selectedUserFromGroup.splice(index, 1);
          return;
        }
      });

      let userNameInitial = userToAdd.name[0];

      let index = filteredUsersGroup.findIndex(
        ({ type }) => type === userNameInitial.toUpperCase()
      );

      let typeExists = index > -1;

      if (!typeExists) {
        index = filteredUsersGroup.findIndex(
          ({ type }) => userNameInitial.toUpperCase() < type
        );

        let isGreatest = index === -1;

        let addToArray = {
          type: userNameInitial.toUpperCase(),
          usersWithInitial: [userToAdd],
        };

        if (!isGreatest) state.filteredUsersGroup.splice(index, 0, addToArray);
        if (isGreatest) state.filteredUsersGroup.push(addToArray);
      }

      if (typeExists) {
        index = filteredUsersGroup[index].usersWithInitial.findIndex(
          ({ name }) => userToAdd.name > name
        );

        state.filteredUsersGroup[index].usersWithInitial.splice(
          index,
          0,
          userToAdd
        );
      }
    },

    displayAddFile(state, action) {
      state.showAddFile = action.payload;
    },

    tempGroupImage(state, action) {
      state.tempGroupImage = action.payload;
    },

    displayGroupDetails(state, action) {
      state.showGroupDetails = action.payload;
    },

    setNewGroupPopup(state, action) {
      state.showNewGroupPopup = action.payload;
    },

    setPickerNewGroup(state, action) {
      state.showPickerNewGroup = action.payload;
    },

    setGroupName(state, aciton) {
      state.newGroupName = aciton.payload;
    },

    clearGroupSelections(state) {
      state = { ...state, ...INITIAL_GROUP_SELECTION };
      return state;
    },

    clearGroupDetails(state) {
      state = { ...state, ...INITIAL_GROUP_DETAILS };
      return state;
    },
  },
});

export const {
  sidebarDisplayStatus,
  setTabPanel,
  showBackdrop,
  displaySidebarHeaderPopup,
  showSidebarActions,
  showForwardModal,
  filterUsers,
  selections,
  clearSelections,
  setSelectedUsersNames,
  setSelectedUsernames,
  showTabPanel,
  displayNewGroup,
  usersFilterGroup,
  filterUsersWithInitials,
  addSelectedUser,
  clearSelectedUsers,
  closePill,
  displayAddFile,
  tempGroupImage,
  displayGroupDetails,
  setNewGroupPopup,
  setPickerNewGroup,
  setGroupName,
  clearGroupSelections,
  clearGroupDetails,
} = uiSlice.actions;

export default uiSlice.reducer;
