import {
  block,
  clear,
  deleteModal,
  muteNotifications,
  report,
  unblock,
  unstarModal,
} from "./modal-details";

export const inputName = {
  email: "email",
  password: "password",
};

export const EMAIL = inputName.email;
export const PASSWORD = inputName.password;

export const initialUserData = {
  id: "",
  name: "",
  email: "",
  avatar: "",
  token: "",
};

export const initialState = {
  values: { email: "", password: "" },
  showErrors: { email: "", password: "" },
  userData: initialUserData,
  isLoggedIn: false,
  showLoader: false,
};

//
//
// Session State
//
//

const messagesInfo = [
  {
    sender: "",
    content: "",
    sentAt: "",
    isRead: false,
    avatar: "",
    dateTag: "",
    messageStatus: "",
  },
];

export const messagesDocument = [
  {
    docName: "Resume - Ankita Gondaliya.doc",
    docMeta: "1 page - PDF - 3 MB",
    sentAt: "Yesterday",
    sentByUser: false,
    isForwarded: false,
    docType: "doc",
  },
  {
    docName: "Resume - Ankita Gondaliya.pdf",
    docMeta: "1 page - PDF - 3 MB",
    sentAt: "Today",
    sentByUser: true,
    isForwarded: true,
    docType: "pdf",
  },
  {
    docName: "Resume - Ankita Gondaliya.md",
    docMeta: "1 page - PDF - 3 MB",
    sentAt: "26/03/2023",
    sentByUser: false,
    isForwarded: true,
    docType: "file",
  },
];

export const INIT_SESSION_STATE = {
  chatters: [],
  isGroupChat: false,
  messagesInfo,
  messagesWithDates: [],
  lastSeenMessage: "",
  messagesLink: [],
  messagesImage: [],
  messagesAvail: false,
  messagesDocument,
  check: "",
};

//
//
// UI STATE
//
//

export const tabs = ["Media", "Documents", "Links"];

export const modals = [
  block,
  unblock,
  report,
  clear,
  deleteModal,
  unstarModal,
  muteNotifications,
];

export const INITIAL_SELECTION = {
  selectedUsers: [],
  filteredUsers: [],
  selectedMessages: [],
  showForwardFooter: false,
  selectedUsernames: "",
  selectedUsersNames: [],
  showForwardModal: false,
};

export const INITIAL_GROUP_DETAILS = {
  showGroupDetails: false,
  showAddFile: false,
  tempGroupImage: "",
  showNewGroupPopup: false,
  showPickerNewGroup: false,
  newGroupName: "",
};

export const INITIAL_GROUP_SELECTION = {
  showNewGroup: false,
  filterUsersWithInitials: [],
  filteredUsersGroup: [],
  filterUsersGroupOnSelect: [],
  selectedUserFromGroup: [],
  showGroupDetails: false,
};

export const INTIAIL_INFO_SIDEBAR = {
  
}

export const INIT_UI_STATE = {
  displayInfoSidebar: false,
  tabs,
  selectedTab: {
    media: true,
    docs: false,
    links: false,
  },
  selectedTabName: tabs[0],
  showSidebarHeaderPopup: false,
  showTabPanel: false,
  modals,
  showSidebarActions: false,
  displayBackdrop: false,
  ...INITIAL_SELECTION,
  ...INITIAL_GROUP_DETAILS,
  ...INITIAL_GROUP_SELECTION,
};
