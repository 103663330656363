import "./ChatInput.css";

import sendTextIcon from "../../../Assets/sendTextIcon.png";

import {
  chatInputChangeHandler,
  shiftUpHandler,
} from "./chat-bubble-event-handlers";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import { useDispatch, useSelector } from "react-redux";
import { useContext, useState } from "react";
import { socketContext } from "../../Contexts/socket-context/socket-context";
import { socketEvents } from "../../Contexts/socket-context/socket-constants";
import {
  addEmoji,
  resetTypedMessage,
} from "../../../Store/slices/messages-slice";

function ChatInput() {
  const typedMessage = useSelector((state) => state.messages.typedMessage);
  const isShiftPressed = useSelector((state) => state.messages.isShiftPressed);
  const messageContainsText = useSelector(
    (state) => state.messages.messageContainsText
  );
  const selectedChat = useSelector((state) => state.chats.selectedChat);
  const id = useSelector((state) => state.userLogin.userData._id);

  const dispatch = useDispatch();

  const { emitSocketEvent } = useContext(socketContext);

  const [showEmojiModal, setShowEmojiModal] = useState(false);

  const newMessagePayload = {
    sender: id,
    chatId: selectedChat.chatID,
    messageType: "text",
    content: typedMessage,
  };

  let sendMessageIcon;

  if (messageContainsText)
    sendMessageIcon = (
      <img src={sendTextIcon} alt="Send message" className="cursor_pointer" />
    );
  else sendMessageIcon = <i className="fa-solid fa-microphone cursor_pointer"></i>;

  function inputInstructionsHandler({ key: keyPressed }) {
    if (keyPressed === "Enter" && typedMessage && isShiftPressed) {
    } else if (
      keyPressed === "Enter" &&
      typedMessage.trim().length &&
      !isShiftPressed
    ) {
      emitSocketEvent(socketEvents.newMessage, newMessagePayload);
      dispatch(resetTypedMessage());
    }
  }

  return (
    <div className="msg_insert">
      {showEmojiModal ? (
        <div className="emoji_position cursor_pointer">
          <Picker
            data={data}
            theme="light"
            onEmojiSelect={(data) => dispatch(addEmoji(data.native))}
          />
        </div>
      ) : null}
      <div className="file_attach cursor_pointer">
        <i
          className="fa-regular fa-face-smile"
          onClick={() => setShowEmojiModal(!showEmojiModal)}
        ></i>
        <i className="fa-solid fa-paperclip"></i>
      </div>

      <input
        type="text"
        name="text"
        placeholder="Type a Message"
        onFocus={() => setShowEmojiModal(false)}
        onChange={chatInputChangeHandler}
        value={typedMessage}
        onKeyDown={inputInstructionsHandler}
        onKeyUp={shiftUpHandler}
        autoComplete="off"
      />
      {sendMessageIcon}
    </div>
  );
}

export default ChatInput;
