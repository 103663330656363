import { useSelector } from "react-redux";
import { useEffect } from "react";

import ContactList from "./ContactList/ContactList";
import ForwardFooter from "./ForwardFooter/ForwardFooter";

import {
  closeForward,
  handleForwardInputChange,
  setUsers,
} from "./event-handlers";

import "./ForwardMessage.css";

import leftArrowIcon from "../../../Assets/left_arrow.png";
import closeIcon from "../../../Assets/close_white.png";

function ForwardMessage() {
  const showForwardModal = useSelector((state) => state.ui.showForwardModal);

  useEffect(() => {
    setUsers("");
  }, []);

  return showForwardModal ? (
    <section className="forward_container bg_white">
      <header className="forward_header forward_green">
        <button className="forward_green" onClick={closeForward}>
          <img src={closeIcon} alt="Close" />
        </button>
        <p className="forward_font_bold">Forward message to</p>
      </header>

      <section className="forward_searchbar_container">
        <div className="forward_searchbar_content">
          <div className="forward_left">
            <img src={leftArrowIcon} alt="Go back" className="fit_parent" />
          </div>
          <input
            type="text"
            className="forward_searchbar forward_font_bold"
            onChange={handleForwardInputChange}
          />
        </div>
      </section>

      <ContactList />

      <ForwardFooter />
    </section>
  ) : null;
}

export default ForwardMessage;
