import { useDispatch, useSelector } from "react-redux";

import "../Sidebar.css";

import muteIcon from "../../../Assets/Mute.svg";

import { useEffect } from "react";
import {
  getChatsInfoAction,
  getMessagesInfoAction,
} from "../../../Store/services/async-thunks";

function SidebarChats() {
  const filteredChats = useSelector((state) => state.chats.filteredChats);
  const users = useSelector((state) => state.chats.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChatsInfoAction());
  }, [dispatch]);

  return (
    <div className="chat_box">
      {filteredChats.map(
        ({
          time,
          latestMessage,
          latestMessageSender,
          latestMessageStatus,
          showSidebarMessageStatus,
          mutedBy,
          unreadCount,
          isRead,
          chatName,
          chatAvatar,
          pinnedBy,
          isGroupChat,
          chatID,
        }) => {
          const groupMessageSender = latestMessageSender
            ? users.filter(({ _id }) => latestMessageSender === _id)[0].name
            : "";

          let messageStatusIcon;
          if (latestMessageStatus === "delivered" && !isGroupChat) {
            messageStatusIcon = <i className="fa-solid fa-check-double"></i>;
          }

          if (latestMessageStatus === "sent") {
            messageStatusIcon = <i className="fa-solid fa-check"></i>;
          }

          if (latestMessageStatus === "read") {
            messageStatusIcon = (
              <i className="fa-solid fa-check-double blue_tick"></i>
            );
          }

          return (
            <article
              key={Math.random()}
              onClick={() => dispatch(getMessagesInfoAction(chatID))}
            >
              <div className="receiver_chats">
                <img src={chatAvatar} alt="receiver img" />
                <div className="receiver_name">
                  <h5>{chatName}</h5>

                  <div className="display_mess">
                    {showSidebarMessageStatus ? messageStatusIcon : null}
                    {isGroupChat ? <h5>{`${groupMessageSender} `}:</h5> : null}
                    <p
                      className={`${
                        !unreadCount ? "display_mess_p" : "active_mess"
                      }`}
                    >
                      {latestMessage ? latestMessage : "Start a chat"}
                    </p>
                  </div>
                </div>
                <div className="show_time">
                  <h5
                    className={`${
                      unreadCount
                        ? "unread_message_timestamp"
                        : "read_message_timestamp"
                    }`}
                  >
                    {time}
                  </h5>
                  <div className="mess_features">
                    {mutedBy.length ? (
                      <img src={muteIcon} alt="mute icon" />
                    ) : null}
                    {pinnedBy.length ? (
                      <i className="fa-solid fa-thumbtack transform_pinned_icon thumbtack_icon"></i>
                    ) : null}

                    {unreadCount ? <h5>{unreadCount}</h5> : null}
                    <i className="fa-solid fa-angle-down display_switch"></i>
                  </div>
                </div>
              </div>

              <div className="line"></div>
            </article>
          );
        }
      )}
    </div>
  );
}

export default SidebarChats;
