import { sidebarChangeHandler } from "../../../helper_functions/event-handlers";

import "../Sidebar.css";

function SidebarSearch() {
  return (
    <div className="sidebar_searchbox_main">
      <div className=" sidebar_searchbox">
        <i className="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          name="text"
          placeholder="Search or start new chat"
          onChange={sidebarChangeHandler}
        />
      </div>
      <i className="fa-solid fa-filter filter_icon"></i>
    </div>
  );
}

export default SidebarSearch;
