import "./UserInfo.css";

import { useSelector } from "react-redux";

function UserInfo() {
  const selectedChat = useSelector((state) => state.chats.selectedChat);

  const {
    chatAvatar,
    chatName,
    showEmailInContactInfo,
    showStatusInContactInfo,
    otherUsers: [{ email, statusMessage }],
  } = selectedChat;

  const emailContent = showEmailInContactInfo ? <p>{email}</p> : null;
  const statusContent = showStatusInContactInfo ? <p>{statusMessage}</p> : null;

  return (
    <div className="user_container">
      <div className="user_styles user_profile">
        <img src={chatAvatar} alt="Profile Pic" />
        <h5>{chatName}</h5>
        {emailContent}
      </div>

      <div className="user_styles user_caption">
        <h5 className="about">About</h5>
        {statusContent}
      </div>
    </div>
  );
}

export default UserInfo;
