export const defaultModal = {
  type: "default",
  initialHeading: "",
  latterHeading: "",
  radioBtnsVals: [],
  assert: "",
  chechboxLabel: "",
  showLine: false,
  initialDescription: "",
  latterDescription: "",
  showCancel: true,
  actionBtnAlignment: "row",
  actionBtns: [],
  learnMore: false,
  actionClickHandler: () => {},
};

export const deleteModal = {
  ...defaultModal,
  type: "delete",
  initialHeading: "Delete messages ?",
  latterHeading: "",
  chechboxLabel: "Delete file from your phone",
  actionBtns: ["Delete for me"],
};

export const unstarModal = {
  ...defaultModal,
  type: "unstar",
  initialHeading: "Unstar all messages ?",
  actionBtns: ["unstar"],
};

export const muteNotifications = {
  ...defaultModal,
  type: "muteNotifications",
  initialHeading: "Mute",
  radioBtnsVals: ["8 Hours", "1 Week", "Always"],
  actionBtns: ["MUTE NOTIFICATION"],
};

export const block = {
  ...defaultModal,
  type: "block",
  initialHeading: "Block",
  latterHeading: "?",
  chechboxLabel: "Report contact",
  assert: "The last 5 messages from this user will be forwarded to WhatsApp.",
  showLine: true,
  initialDescription:
    "Blocked contacts will no longer be able to call you or send you messages. This contact will not be notified.",
  actionBtns: ["block"],
};

export const unblock = {
  ...defaultModal,
  type: "unblock",
  initialHeading: "Unblock",
  latterHeading: "?",
  actionBtns: ["UNBLOCK"],
};

export const report = {
  ...defaultModal,
  type: "report",
  initialHeading: "Report this contact to WhatsApp ?",
  chechboxLabel: "Report this contact to WhatsApp ?",
  showLine: true,
  initialDescription:
    "The last 5 messages from this business will be forwarded to WhatsApp. If you block this business and clear the chat, messages will only be removed from this device and your devices on the newer version of WhatsApp.",
  latterDescription: "This business will not be notified.",
  actionBtns: ["REPORT"],
};

export const clear = {
  ...deleteModal,
  type: "clear",
  initialHeading: "Changes to clearing or deleting a chat",
  initialDescription:
    "Clearing or deleting entire chats will only remove messages from this device and your devices on the newer versions of WhatsApp.",
  actionBtns: ["CONTINUE"],
};
