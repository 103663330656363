import ChatscreenHeader from "./ChatsHeader/ChatsHeader";
import ChatInput from "./ChatInput/ChatInput";
import Sidebar from "../Sidebar/Sidebar";

import { useSelector } from "react-redux";

import ChatBody from "./ChatBody/ChatBody";
import ChatscreenPlaceholder from "../ChatscreenPlaceholder/ChatscreenPlaceholder";
import ContactInfo from "../ContactInfoSidebar/ContactInfo";
import NewGroup from "../Sidebar/NewGroup/NewGroup";

function Chatscreen() {
  const isInitialStartup = useSelector((state) => state.chats.isInitialStartup);
  const displayInfoSidebar = useSelector(
    (state) => state.ui.displayInfoSidebar
  );
  const showNewGroup = useSelector((state) => state.ui.showNewGroup);
  const showGroupDetails = useSelector((state) => state.ui.showGroupDetails);

  return (
    <div className="main">
      <div className="main_header">
        <div className="whatsapp_box">
          <div className="main_container">
            <div className="left_side">
              {!showNewGroup && !showGroupDetails ? <Sidebar /> : <NewGroup />}
            </div>
            <div className="right_side">
              {isInitialStartup ? (
                <ChatscreenPlaceholder />
              ) : (
                <>
                  <div className="chat_main">
                    <ChatscreenHeader />

                    <ChatBody />
                  </div>

                  <ChatInput />
                </>
              )}
            </div>
            {displayInfoSidebar ? <ContactInfo /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chatscreen;
