import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../../constants/redux";

const userLogin = createSlice({
  initialState: initialState,
  name: "userLogin",
  reducers: {
    handleInputChange(state, action) {
      const { name, value } = action.payload;
      state.values[name] = value;
    },

    handleFormSubmit(state) {
      const { email, password } = state.values;

      // eslint-disable-next-line no-useless-escape
      const emailValid = email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

      // ^                               start anchor
      // (?=(.*[a-z]){3,})               lowercase letters. {3,} indicates that you want 3 of this group
      // (?=(.*[A-Z]){2,})               uppercase letters. {2,} indicates that you want 2 of this group
      // (?=(.*[0-9]){2,})               numbers. {2,} indicates that you want 2 of this group
      // (?=(.*[!@#$%^&*()\-__+.]){1,})  all the special characters in the [] fields. The ones used by regex are escaped by using the \ or the character itself. {1,} is redundant, but good practice, in case you change that to more than 1 in the future. Also keeps all the groups consistent
      // {8,}                            indicates that you want 8 or more
      // $                               end anchor

      const conditions = password.match(
        /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()]){1,}).{8,}$/
      );

      const hasSpaces = password.trim().length !== password.length;

      const passwordValid = conditions && !hasSpaces;

      state.showErrors.email = !emailValid
        ? "Please enter a valid email address"
        : false;

      state.showErrors.password = !passwordValid
        ? "Password must be at least 8 characters long and contain at least oneuppercase letter, one lowercase letter, one number, and one specialcharacter (!@#$%^&*()), and cannot contain spaces or be the same as yourusername."
        : false;
    },

    setUserData(state, action) {
      const response = action.payload;
      state.userData = response;
      state.isLoggedIn = true;
    },

    setLoaderStatus(state, action) {
      state.showLoader = action.payload;
    },

    handleServerSideError(state, action) {
      state.showErrors = action.payload;
    },
  },
});

export const {
  handleInputChange,
  handleFormSubmit,
  setUserData,
  handleServerSideError,
  setLoaderStatus,
} = userLogin.actions;

export default userLogin.reducer;
