import { store } from "../../../../Store/store/store";
import { clearSelections, showBackdrop, showForwardModal } from "../../../../Store/slices/ui-slice";

import { socketEvents } from "../../../Contexts/socket-context/socket-constants";

const { dispatch, getState } = store;

export function forwardMessages(socket) {
  return function () {
    const { selectedUsers, selectedMessages } = getState().ui;

    const { _id } = getState().userLogin.userData;

    const numUsers = selectedUsers.length;
    const numSelectedMessages = selectedMessages.length;

    for (let i = 0; i < numUsers; i += 1) {
      const { chatID } = selectedUsers[i];

      for (let j = 0; j < numSelectedMessages; j += 1) {
        const { messageType, content } = selectedMessages[j];

        const payload = { sender: _id, chatId: chatID, messageType, content };

        socket.emit(socketEvents.newMessage, payload);
      }
    }
  };
}

export function handleForwardingMessages(socket) {
  dispatch(forwardMessages(socket));
  dispatch(showBackdrop(false));
  dispatch(showForwardModal(false))
  dispatch(clearSelections());
}
