import React from "react";
import "./LoadingLogin.css";
function LoadingLogin() {
  return (
    <div className="loading">
      <div className="loader"></div>
      <p>PLEASE WAIT WHILE WE ARE SIGNING YOU IN</p>
    </div>
  );
}

export default LoadingLogin;
