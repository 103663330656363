import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddGroupPicPopup from "../../Popups/SidebarHeaderPopup/AddGroupPicPopup/AddGroupPicPopup";
import DetailsDisplay from "./DetailsDisplay/DetailsDisplay";
import EmojiPicker from "./EmojiPicker/EmojiPicker";
import DetailsSettings from "./DetailsSettings/DetailsSettings";
import DetailsSubject from "./DetailsSubject/DetailsSubject";

import {
  convertToBase64,
  fileChangeHandler,
} from "../../../helper_functions/helpers";
import { imageExts } from "../../../constants/consts";
import {
  createGroup,
  displayPickerNewGroup,
  enterGroupName,
  setTempGroupImage,
} from "./event-handlers";

import "./GroupDetails.css";

import green_tick from "../../../Assets/green_tick.png";

function GroupDetails() {
  const showAddFile = useSelector((state) => state.ui.showAddFile);
  const showNewGroupPopup = useSelector((state) => state.ui.showNewGroupPopup);
  const showPickerNewGroup = useSelector(
    (state) => state.ui.showPickerNewGroup
  );
  const newGroupName = useSelector((state) => state.ui.newGroupName);

  const [file, setFile] = useState();
  const [base64, setBase64] = useState();

  const fileInputRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (showAddFile) fileInputRef.current.click();

    if (file) convertToBase64(file, setBase64);

    if (base64) setTempGroupImage(base64);
  }, [file, showAddFile, base64]);

  return (
    <>
      <section className="details_container box_shadow mb_14">
        <DetailsDisplay state={showNewGroupPopup} />

        <AddGroupPicPopup show={showNewGroupPopup} />

        <DetailsSubject
          inputValue={newGroupName}
          inputChangeHandler={enterGroupName}
          invertPickerStatus={displayPickerNewGroup.bind(
            null,
            !showPickerNewGroup
          )}
        />

        <div className="details_line"></div>
      </section>

      <DetailsSettings />

      <section className="details-create_container justify_center">
        <button
          disabled={!newGroupName}
          className="create_btn cursor_pointer"
          onClick={() => dispatch(createGroup())}
        >
          <img src={green_tick} alt="create group" />
        </button>
      </section>

      <EmojiPicker
        show={showPickerNewGroup}
        insertEmojiHandler={enterGroupName}
      />

      <input
        style={{ display: "none" }}
        type="file"
        onChange={(e) => fileChangeHandler(e, imageExts, setFile)}
        accept="image/*"
        ref={fileInputRef}
      />
    </>
  );
}

export default GroupDetails;
