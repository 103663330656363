import smiley from "../../../../Assets/Smiley.svg";

function DetailsSubject(props) {
  const { inputValue, inputChangeHandler, invertPickerStatus } = props;
  return (
    <div className="details_subject space_between mb_14">
      <input
        placeholder="Group Subject"
        className="details_font_light"
        onChange={inputChangeHandler}
        value={inputValue}
      />

      <img
        src={smiley}
        alt=""
        onClick={invertPickerStatus}
        className="cursor_pointer"
      />
    </div>
  );
}

export default DetailsSubject;
