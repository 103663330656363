/* eslint-disable no-unused-expressions */
import "./DocumentPreview.css";

import downloadIcon from "../../../Assets/download.png";
import pdfIcon from "../../../Assets/pdf.png";
import fileIcon from "../../../Assets/file.png";
import documentIcon from "../../../Assets/Document_Icon.png";

function DocumentPreview(props) {
  const { docName, docMeta, sentAt, sentByUser, isForwarded, docType } =
    props.message;

  let docMessagesStyles = "doc_message";
  !sentByUser ? (docMessagesStyles += " doc_message_received") : "";

  let docFileStyles = "doc_file";
  !sentByUser ? (docFileStyles += " doc_file_received") : "";

  let fileTypeIcon;

  switch (docType) {
    case "doc":
      fileTypeIcon = <img src={documentIcon} alt="Document" />;
      break;
    case "pdf":
      fileTypeIcon = <img src={pdfIcon} alt="PDF" />;
      break;
    case "file":
      fileTypeIcon = <img src={fileIcon} alt="File" />;
      break;
    default:
      fileTypeIcon = <img src={fileIcon} alt="File" />;
  }

  return (
    <>
      <div className="doc_preview">
        <input type="checkbox" name="checkbox" />

        <div className={docMessagesStyles}>
          {isForwarded ? (
            <div className="forward_doc">
              <i className="fa-solid fa-share"></i>
              <h5>Forwarded</h5>
            </div>
          ) : null}

          <div className={docFileStyles}>
            {fileTypeIcon}
            <div>
              <p>{docName}</p>
              <h5>{docMeta}</h5>
            </div>
            <img src={downloadIcon} alt="Download" />
          </div>

          <span className="doc_time">
            <h5>{sentAt}</h5>
            <i className="fa-solid fa-check-double"></i>
          </span>
        </div>
      </div>

      <div className="line"></div>
    </>
  );
}

export default DocumentPreview;
