function User(props) {
  const { avatar, name, statusMessage } = props.user;

  return (
    <>
      <div className="ng_contact">
        <div className="ng_contact_img">
          <img src={avatar} alt="User Profile Pic" className="fit_content" />
        </div>
        <div className="ng_contact_details">
          <h4 className="ng_font_common fw_500 ng_font_light">{name}</h4>
          <p className="ng_font_common fw_400 ng_font_lighter pt_2">
            {statusMessage}
          </p>
        </div>
      </div>
      <div className="ng_line"></div>
    </>
  );
}

export default User;
