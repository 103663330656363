import "./MediaThumbnail.css";
import { inputChangeHandler } from "./event-handlers";

function MediaThumbnail(props) {
  const { content, starredByLoggedInUser, messageType } = props.message;

  const star = (
    <div>
      <i className="fa-solid fa-star media_starred"></i>
    </div>
  );

  return (
    <article key={Math.random()}>
      <div className="media_thumbnail">
        <label className="label">
          <input
            className="label__checkbox"
            type="checkbox"
            onClick={(event) => inputChangeHandler(event, props.message)}
          />
          <span className="label__text">
            <span className="label__check">
              <i className="fa fa-check icon"></i>
            </span>
          </span>
        </label>

        <img src={content} alt={`Message is ${messageType}`} />
        {starredByLoggedInUser ? star : null}
      </div>
    </article>
  );
}

export default MediaThumbnail;
