/* eslint-disable no-unused-expressions */
import "./LinksPreview.css";
import "./LinkPreviewHeader.css";

import linkPlaceholder from "../../../Assets/linkPlaceholder.png";
import playButton from "../../../Assets/playButton.png";

function LinksPreview(props) {
  const {
    URL,
    linkMeta,
    sentAt,
    sentByUser,
    isForwarded,
    videoTitle,
    linkMessage,
    avatar,
    sender,
    sentDay,
    isLinkVideo,
    isNormalLink,
  } = props.message;

  let fileTypeIcon;

  fileTypeIcon = (
    <figure
      className={`center_item ${
        isNormalLink
          ? "link_link_placeholder"
          : isLinkVideo
          ? "link_video_play"
          : ""
      }`}
    >
      <img src={isLinkVideo ? playButton : linkPlaceholder} alt="link" />
    </figure>
  );

  const forwardLabel = isForwarded ? (
    <div className="forward_link">
      <i className="fa-solid fa-share"></i>
      <h5 className="link_font_small">Forwarded</h5>
    </div>
  ) : null;

  return (
    <div className="third_section">
      <>
        return (
        <section className="link_container">
          <section className="link_header">
            <figure className="link_header_left">
              <img src={avatar} alt="Sender avatar" />
              <figcaption className="link_header_font">{sender}</figcaption>
            </figure>

            <span className="link_header_right">
              <h5 className="link_header_font">{sentDay}</h5>
              <i className="fa-solid fa-caret-right"></i>
            </span>
          </section>

          <section
            className={`link_preview ${isLinkVideo && "link_preview_video"}`}
          >
            <div
              className={`link_message ${
                !sentByUser && "link_message_received"
              } ${isLinkVideo && "link_message_video"}`}
            >
              {forwardLabel}
              <div
                className={`link_file ${!sentByUser && "link_file_received"} ${
                  isLinkVideo && "link_file_video"
                }`}
              >
                {fileTypeIcon}
                <div>
                  <h5 className="links_font_large mb_6">{videoTitle}</h5>
                  <p className="link_font_small p_wrap">{URL}</p>
                  <h5 className="link_font_small">{linkMeta}</h5>
                </div>
              </div>

              <section className="link_footer">
                <span className="link_footer_content">
                  {linkMessage ? (
                    <p className="links_font_large">{linkMessage}</p>
                  ) : null}
                  <a
                    className="link_footer_link link_truncate links_font_large"
                    href={URL}
                  >
                    {URL}
                  </a>
                </span>
                <h5 className="link_font_small">{sentAt}</h5>
                <i className="fa-solid fa-check-double"></i>
              </section>
            </div>
          </section>
          <span className="line"></span>
        </section>
        );
      </>
    </div>
  );
}

export default LinksPreview;
