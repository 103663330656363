import { useState } from "react";

import GroupDetailsButton from "../GroupDetailsButton/GroupDetailsButton";
import Pills from "../Pills/Pills";
import SearchBar from "../SearchBar/SearchBar";
import UsersList from "../UsersList/UsersList";

function GroupAddUsers() {
  const [typedInput, setTypedInput] = useState("");

  return (
    <>
      <main className="ng_main">
        <section className="ng_pills">
          <Pills typedInput={typedInput} />
        </section>

        <section className="ng_search">
          <SearchBar
            setTypedInput={setTypedInput}
            placeholder="Type contact name"
          />
          <div className="ng_line"></div>
        </section>

        <UsersList typedInput={typedInput} />
      </main>

      <GroupDetailsButton />
    </>
  );
}

export default GroupAddUsers;
