function DangerGroup() {
  return (
    <section className="danger_styles danger_group_box_shdw">
      <div className="danger_cta">
        <i className="fa-solid fa-ban"></i>

        <h5>Exit group</h5>
      </div>

      <div className="danger_cta">
        <i className="fa-solid fa-thumbs-up fa-rotate-90"></i>

        <h5>Report group</h5>
      </div>
    </section>
  );
}

export default DangerGroup;
