import { store } from "../Store/store/store";
import { sendLoginRequestAction } from "../Store/services/async-thunks";

import { handleInputChange } from "../Store/slices/user-login";
import { handleSidebarInputChange } from "../Store/slices/allChats-slice";

import {
  displaySidebarHeaderPopup,
  sidebarDisplayStatus,
} from "../Store/slices/ui-slice";

const { dispatch, getState } = store;

export const inputChangeHandler = (event) => {
  const {
    target: { name, value },
  } = event;

  dispatch(handleInputChange({ name, value }));
};

export const formSubmitHandler = (event) => {
  event.preventDefault();

  dispatch(sendLoginRequestAction());
};

export const sidebarChangeHandler = (event) => {
  dispatch(handleSidebarInputChange(event.target.value));
};

export function showInfoSidebar(value) {
  dispatch(sidebarDisplayStatus(value));
}

export function checkEllipsesClick(event) {
  function setListenerStatus() {
    const { showSidebarHeaderPopup } = getState().ui;
    event.target.id === "ellipses"
      ? dispatch(displaySidebarHeaderPopup(!showSidebarHeaderPopup))
      : dispatch(displaySidebarHeaderPopup(false));
  }

  setListenerStatus();
}
