import { useSelector } from "react-redux";

import { viewGroupDetails } from "./event-handlers";

import "./GroupDetailsButton.css";

import greenRightArrow from "../../../../Assets/greenRightArrow.svg";

function GroupDetailsButton() {
  const selectedUserFromGroup = useSelector(
    (state) => state.ui.selectedUserFromGroup
  );

  return selectedUserFromGroup.length ? (
    <>
      <section className="su_container">
        <article className="select_btn">
          <img
            onClick={viewGroupDetails}
            src={greenRightArrow}
            alt="accept selections"
            className="fit_content"
          />
        </article>
      </section>
    </>
  ) : null;
}

export default GroupDetailsButton;
