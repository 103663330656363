import "./HeaderPopupListButton.css";

function HeaderPopupListButton(props) {
  const { item, clickHandler } = props.item;

  return (
    <button id="hp_button" onClick={clickHandler}>
      {item}
    </button>
  );
}

export default HeaderPopupListButton;
