import React from "react";
import { Provider } from "react-redux";

import ReactDOM from "react-dom/client";

import App from "./Components/App/App";

import "./index.css";

import { store } from "./Store/store/store";
import SocketContextProvider from "./Components/Contexts/socket-context/SocketContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      <SocketContextProvider>
        <App />
      </SocketContextProvider>
    </Provider>
  </>
);
