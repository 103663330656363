import { useSelector } from "react-redux";

import GroupHeader from "./GroupHeader/GroupHeader";
import GroupDetails from "../GroupDetails/GroupDetails";
import GroupAddUsers from "./GroupAddUsers/GroupAddUsers";

import "./NewGroup.css";

function NewGroup() {
  const showGroupDetails = useSelector((state) => state.ui.showGroupDetails);

  return (
    <section className="ng_container">
      <header className="ng_header">
        <GroupHeader
          isGroupDetailsOpened={showGroupDetails}
          heading={showGroupDetails ? "New Group" : "Add group participants"}
        />
      </header>

      {showGroupDetails ? <GroupDetails /> : <GroupAddUsers />}
    </section>
  );
}

export default NewGroup;
