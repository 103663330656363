import { filterUsersByInitials } from "../Components/Popups/SidebarHeaderPopup/event-handlers";

export const menuItems = [
  { item: "New Group", clickHandler: filterUsersByInitials },
  { item: "New Community", clickHandler: () => {} },
  { item: "Starred messages", clickHandler: () => {} },
  { item: "Selected chats", clickHandler: () => {} },
  { item: "Settings", clickHandler: () => {} },
  { item: "Log out", clickHandler: () => {} },
];

export const imageExts = ["png", "jpg", "jpeg"];
