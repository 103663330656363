import axios from "axios";
import { baseURL } from "../constants/api-endpoints";
import { store } from "../Store/store/store";

export const API = axios.create({ baseURL });

API.interceptors.request.use((req) => {
  const token = store.getState().userLogin.userData.token;
  if (token) {
    req.headers.Authorization = "Bearer " + token;
  }
  return req;
});
