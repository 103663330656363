import { store } from "../../../Store/store/store";

import {
  clearGroupDetails,
  clearGroupSelections,
  displayAddFile,
  setGroupName,
  setNewGroupPopup,
  setPickerNewGroup,
  tempGroupImage,
} from "../../../Store/slices/ui-slice";
import { API } from "../../../helper_functions/axios";
import { endpoints } from "../../../constants/api-endpoints";
import {
  getChatsInfoAction,
  getMessagesInfoAction,
} from "../../../Store/services/async-thunks";

const { dispatch, getState } = store;

export function showAddFile() {
  dispatch(displayAddFile(true));

  setTimeout(() => {
    dispatch(displayAddFile(false));
    dispatch(setNewGroupPopup(false));
  }, 150);
}

export function setTempGroupImage(base64) {
  dispatch(tempGroupImage(base64));
}

export function createGroup() {
  return async function () {
    const { newGroupName, selectedUserFromGroup, tempGroupImage } =
      getState().ui;

    const userIds = selectedUserFromGroup.map((user) => user._id);

    const requestBody = {
      name: newGroupName,
      users: userIds,
      avatar: tempGroupImage,
    };

    API.post(endpoints.groupEndpoints.create, requestBody).then((resp) => {
      dispatch(getChatsInfoAction());
      dispatch(clearGroupSelections());
      dispatch(clearGroupDetails());

      // w/o this error maybe something is used before it was defined
      setTimeout(() => {
        dispatch(getMessagesInfoAction(resp.data._id));
      }, 500);
    });
  };
}

export function flipState(getter, setter) {
  getter(!setter);
}

export function displayNewGroupPopup(bool) {
  dispatch(setNewGroupPopup(bool));
}

export function displayPickerNewGroup(bool) {
  dispatch(setPickerNewGroup(bool));
}

export function enterGroupName(e) {
  let value;

  if (e.native) {
    const { newGroupName } = getState().ui;
    value = newGroupName + e.native;
  } else {
    value = e.target.value;
  }

  dispatch(setGroupName(value));
}
