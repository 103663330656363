import "./MessageType.css";

function MessageType(props) {
  const {
    children,
    messageContent: { isMessageImage, content },
  } = props;

  switch (isMessageImage) {
    case true:
      return (
        <figure className="chat_image">
          <img src={content} alt="message" />

          {children}
        </figure>
      );
    case false:
      return (
        <article className="message_body">
          <p>{content}</p>

          {children}
        </article>
      );
    default:
      return <span>isMessageTime isn't boolean</span>;
  }
}

export default MessageType;
