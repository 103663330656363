import { endpoints } from "../../constants/api-endpoints";

import { API } from "../../helper_functions/axios";
import {
  changeMuteStatus,
  removeChatscreenPlaceholder,
  resetMuteRequestStatus,
  setSelectedChat,
  transform,
} from "../slices/allChats-slice";

import {
  filterMessagesByDate,
  setChateeOnline,
  setChatters,
  setMessagesInfo,
  sortMediaDates,
  sortMessageTypes,
} from "../slices/chats-slice";

import {
  handleFormSubmit,
  handleServerSideError,
  setLoaderStatus,
  setUserData,
} from "../slices/user-login";

export const sendLoginRequestAction = function () {
  return async function (dispatch, getState) {
    dispatch(handleFormSubmit());

    const {
      values,
      showErrors: { email: emailError, password: passwordError },
    } = getState().userLogin;

    const noError = !emailError && !passwordError;

    if (noError) {
      dispatch(setLoaderStatus(true));

      try {
        const response = await API.post(endpoints.userEndpoints.login, values);
        dispatch(setUserData(response.data));
      } catch (error) {
        const errorMessage = error.response.data.error;
        dispatch(handleServerSideError(errorMessage));
        dispatch(setLoaderStatus(false));
      }
    }
  };
};

export const getChatsInfoAction = function () {
  return async function (dispatch, getState) {
    const loggedInUserID = getState().userLogin.userData._id;
    const { data: chats } = await API.get(endpoints.chatsEndpoints.getAllChats);
    dispatch(transform({ chats, loggedInUserID }));
  };
};

export const getMessagesInfoAction = function (chatID) {
  return async function (dispatch, getState) {
    dispatch(removeChatscreenPlaceholder());
    dispatch(setSelectedChat(chatID));
    const selectedChat = getState().chats.selectedChat;
    const loggedInUserID = getState().userLogin.userData._id;

    dispatch(setChatters(selectedChat));
    dispatch(setChateeOnline(loggedInUserID));
    const { data: messages } = await API.get(
      `${endpoints.messageEndpoints.getAllMessages + "/" + chatID}`
    );

    const msgInfoPayload = { messages, loggedInUserID };
    dispatch(setMessagesInfo(msgInfoPayload));
    dispatch(sortMessageTypes());
    dispatch(sortMediaDates());
    dispatch(filterMessagesByDate());
  };
};

export function handleMuteStatusChange(loggedInUserID) {
  return async function (dispatch, getState) {
    const chatID = getState().chats.selectedChat.chatID;

    const { data: chat } = await API.post(
      endpoints.chatsEndpoints["mute/unMute"],
      {
        chatId: chatID,
      }
    );

    dispatch(changeMuteStatus({ chat, loggedInUserID }));
    dispatch(resetMuteRequestStatus());
  };
}
