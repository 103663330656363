export function extractTime(date) {
  const timeStamp = new Date(date);

  const tempTimeHours = timeStamp.getHours();

  let timeHours;

  let AM_PM;

  if (tempTimeHours > 12) {
    AM_PM = "PM";
    timeHours = (tempTimeHours - 12).toString();
  } else if (tempTimeHours === 0) timeHours = "00";
  else {
    AM_PM = "AM";
    timeHours = tempTimeHours.toString();
  }

  const tempTimeMins = timeStamp.getMinutes();

  let timeMins;

  if (tempTimeMins < 10) timeMins = "0" + tempTimeMins.toString();
  else if (tempTimeMins === 0) timeMins = "00";
  else timeMins = tempTimeMins.toString();

  const time = timeHours + ":" + timeMins + " " + AM_PM;

  return time;
}

export function returnChatterAvatar(loggedInUserID, checkID, chatters) {
  if (checkID !== loggedInUserID) {
    return chatters.filter((chatter) => checkID === chatter.id);
  } else {
    return [{ id: loggedInUserID, avatar: null }];
  }
}

// TODO: look into params of these and axiosRequest. since both are similar if not same.

export function generateRequestConfig(requestMethod, url, data = null) {
  const requestConfig = {
    requestMethod,
    url,
    data,
  };

  return requestConfig;
}

export function returnLastSeenMessage(onlineStatus) {
  if (onlineStatus === "Online") return "Online";
  else {
    const timeStamp = new Date(onlineStatus);

    const time = extractTime(onlineStatus);

    const tsDate = timeStamp.getDate();
    const tsMonth = timeStamp.getMonth();
    const tsYear = timeStamp.getFullYear();

    const currentDate = new Date();

    const currMonth = currentDate.getMonth();
    const currDate = currentDate.getDate();
    const currYear = currentDate.getFullYear();

    const isCurrMonth = tsYear === currYear && tsMonth === currMonth;

    if (isCurrMonth && tsDate === currDate) {
      return `last seen today ${time}`;
    } else if (isCurrMonth && currDate === tsDate + 1) {
      return `last seen yesterday at ${time}`;
    } else {
      return `last seen ${tsDate}/${tsMonth}/${tsYear} at ${time}`;
    }
  }
}

export function randomColourGenerator() {
  function randomizer() {
    return Math.random() * 255;
  }
  const red = randomizer();
  const blue = randomizer();
  const green = randomizer();

  return { red, blue, green };
}

export function getUniqueInitials(array) {
  // array => Array<user{}>
  let initials = new Set();

  array.forEach((user) => {
    const firstLetter = user.name[0];
    initials.add(firstLetter.toUpperCase());
  });

  return [...initials];
}

export function getUsersWithInitials(initials, users) {
  // initials => Array<string>
  // user => Array<user{}>

  let usersWithInitials = [];

  initials.forEach((initial) => {
    const usersWithInitial = getUsersWithSameInitial(initial, users);
    const usersOfInitials = { type: initial, usersWithInitial };

    usersWithInitials.push(usersOfInitials);
  });

  return usersWithInitials;
}

export function getUsersWithSameInitial(initial, users) {
  // initial => String
  // user => Array<user{}>

  let usersWithInitial = [];

  users.forEach((user) => {
    const firstLetter = user.name[0];

    if (firstLetter.toUpperCase() === initial) {
      usersWithInitial.push(user);
    }
  });

  return usersWithInitial;
}

export function checkFileType(filename, extensions) {
  let split = filename.split(".");

  let extension = split.pop();

  if (extensions.includes(extension)) return true;
  else return false;
}

export function checkFileSize(file) {
  let fileSize = file.size;

  console.log("file", file);
  fileSize /= 1024; // in KB

  if (fileSize < 70) return true;
  else return false;
}

export function fileChangeHandler(e, extensions, stateSetter) {
  let file = e.target.files[0];

  if (!checkFileSize(file)) {
    alert("Selected file should be smaller than 70 KB");
    return;
  }

  let filename = e.target.value;

  if (!checkFileType(filename, extensions)) {
    alert("Select proper file type (PNG, JPG, JPEG)");
    return;
  }

  stateSetter(e.target.files[0]);
}

export function convertToBase64(file, stateSetter) {
  let reader = new FileReader();

  reader.readAsDataURL(file);

  reader.addEventListener("loadend", (e) => {
    stateSetter(e.target.result);
  });
}
