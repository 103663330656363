import { useSelector } from "react-redux";

import { displayNewGroupPopup } from "../event-handlers";

import defaultGroup from "../../../../Assets/default_group_icon.png";
import camera from "../../../../Assets/camera.png";

function DetailsDisplay({ state }) {
  const tempGroupImage = useSelector((state) => state.ui.tempGroupImage);

  return (
    <div className="details_display justify_center mb_14">
      <div
        className="details_img justify_center cursor_pointer"
        onClick={displayNewGroupPopup.bind(null, !state)}
      >
        {!tempGroupImage ? (
          <>
            <img src={defaultGroup} alt="Group" className="img" />
            <img src={camera} alt="" className="absolute top_200" />
            <p className="cam_text absolute top_240 fs_14">ADD GROUP ICON</p>
          </>
        ) : (
          <img src={tempGroupImage} alt="" className="fit_content" />
        )}
      </div>
    </div>
  );
}

export default DetailsDisplay;
