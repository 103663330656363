import { useSelector } from "react-redux";

import MessageType from "./MessageType/MessageType";

import "./ChatBubble.css";

function ChatBubble({ dateTag, messagesDateTag }) {
  const selectedChat = useSelector((state) => state.chats.selectedChat);

  return (
    <section>
      <div className="chat_date">
        <h5>{dateTag}</h5>
      </div>

      {messagesDateTag.map((message) => {
        if (!messagesDateTag.length) return null;

        const {
          content,
          sentAt,
          isSenderLoggedInUser,
          avatar,
          senderName,
          messageStatus,
          isMessageImage,
          chatterNameColour: { red, green, blue },
        } = message;

        let messageStatusIcon = null;

        if (isSenderLoggedInUser && isMessageImage) {
          switch (messageStatus) {
            case "read":
              messageStatusIcon = (
                <i className="fa-solid fa-check-double blue_tick"></i>
              );
              break;
            case "delivered":
              messageStatusIcon = <i className="fa-solid fa-check-double"></i>;
              break;
            case "sent":
              messageStatusIcon = <i className="fa-solid fa-check"></i>;
              break;
            default:
              messageStatusIcon = "";
          }
        }

        const chatMessageID = isMessageImage
          ? ""
          : isSenderLoggedInUser
          ? "sent"
          : "received";

        const showAvatar = selectedChat.isGroupChat && avatar ? true : false;
        const showChatterInfo =
          selectedChat.isGroupChat && !isSenderLoggedInUser;

        const messageContent = { isMessageImage, content };

        return (
          <div key={Math.random()} className="inner_container">
            {showAvatar && <img src={avatar} alt="Sender's profile pic" />}
            <div
              id={chatMessageID}
              className={`chat_msg ${
                isSenderLoggedInUser ? "send_msg" : "received_msg"
              }`}
            >
              <section className="chat_bubble">
                {showChatterInfo ? (
                  <h5
                    className="chatterName"
                    style={{ color: `rgb(${red}, ${blue}, ${green})` }}
                  >
                    {senderName}
                  </h5>
                ) : null}

                <MessageType messageContent={messageContent}>
                  <div
                    className={`user_chat_time ${
                      isMessageImage && "chat_time_img"
                    }`}
                  >
                    <h5>{sentAt}</h5>
                    {messageStatusIcon}
                  </div>
                </MessageType>
              </section>
            </div>
          </div>
        );
      })}
    </section>
  );
}

export default ChatBubble;
