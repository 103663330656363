import { useSelector } from "react-redux";

import DangerUser from "./DangerUser/DangerUser";
import DangerGroup from "./DangerGroup/DangerGroup";

import "./DangerCTA.css";

function DangerCTA() {
  const chatName = useSelector((state) => state.chats.selectedChat.chatName);

  const isGroupChat = useSelector(
    (state) => state.chats.selectedChat
  ).isGroupChat;

  return (
    <>
      {!isGroupChat ? <DangerUser chatName={chatName} /> : null}
      {isGroupChat ? <DangerGroup /> : null}
    </>
  );
}

export default DangerCTA;
