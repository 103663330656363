// TODO: 1 export of dispatch and getState

import { showSidebarActions } from "../../../Store/slices/ui-slice";
import { store } from "../../../Store/store/store";
import { setSelectedMessages } from "../../Modals/ForwardMessage/ContactList/Contact/event-handlers";

let { getState, dispatch } = store;

export function inputChangeHandler(event, message) {
  const { checked } = event.target;

  dispatch(setSelectedMessages(message, checked));

  let { selectedMessages } = getState().ui;

  const messagesAreSelected = selectedMessages.length > 0;

  messagesAreSelected
    ? dispatch(showSidebarActions(true))
    : dispatch(showSidebarActions(false));
}
