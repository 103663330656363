// TODO:filter (userName) => userName === name common

import { useSelector } from "react-redux";

import { handleInputChange, isPresent } from "./event-handlers";

import "./Contact.css";

function Contact(props) {
  const { statusMessage, name, avatar } = props.user;

  const selectedUsersNames = useSelector(
    (state) => state.ui.selectedUsersNames
  );

  const isUserPresent = isPresent(selectedUsersNames, name);

  return (
    <>
      <label
        htmlFor={`checkbox${props.user._id}`}
        className="forward_contact_container"
      >
        <input
          id={`checkbox${props.user._id}`}
          type="checkbox"
          className="contact_chechbox forward_green"
          onClick={(event) => handleInputChange(event, props)}
          checked={isUserPresent}
        />

        <div className="contact_content_container">
          <div className="contact_img">
            <img src={avatar} alt="Profile" className="fit_parent" />
          </div>

          <section className="contact_content">
            <div className="contact_name forward_font_bold">{name}</div>
            <div className="contact_status">{statusMessage}</div>
          </section>
        </div>
      </label>
    </>
  );
}

export default Contact;
