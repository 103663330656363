import {
  handleChatInputChange,
  handleEnterDown,
  handleShiftDown,
  handleShiftUp,
  messageChecker,
} from "../../../Store/slices/messages-slice";

import { store } from "../../../Store/store/store";

const { dispatch } = store;

export function chatInputChangeHandler({ target: { value } }) {
  dispatch(handleChatInputChange(value));
  dispatch(messageChecker());
}

export function inputInstructionsHandler({ key }) {
  dispatch(handleShiftDown(key));
  dispatch(handleEnterDown(key));
}

export function shiftUpHandler({ key }) {
  dispatch(handleShiftUp(key));
}
