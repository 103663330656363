/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";

import {
  extractTime,
  randomColourGenerator,
  returnChatterAvatar,
  returnLastSeenMessage,
} from "../../helper_functions/helpers";

import {
  extractMonthYear,
  messageStatusController,
} from "../slices-helper-functions/slice-helper-functions";

import { INIT_SESSION_STATE } from "../../constants/redux";

const sessionSlice = createSlice({
  initialState: INIT_SESSION_STATE,
  name: "session",
  reducers: {
    // to extract chatID for passing it in requesstConfig(url) to set messages
    // setSelectedChatID(state, action) {
    //   state.selectedChatID = action.payload;
    // },

    // runs before setMessageInfo
    // names of users chatting
    setChatters(state, action) {
      const chat = action.payload;

      state.chatters = chat.users.map(
        ({ _id, avatar, name, onlineStatus }) => ({
          id: _id,
          avatar: avatar,
          name: name,
          onlineStatus: onlineStatus,
        })
      );
    },

    // info required in chat bubble.
    setMessagesInfo(state, action) {
      const { messages, loggedInUserID } = action.payload;

      state.messagesInfo = messages.map(
        ({
          sender,
          content,
          createdAt,
          readBy,
          dateTag,
          messageType,
          messageStatus,
          starredBy,
          _id,
        }) => {
          const sentAt = extractTime(createdAt);
          const isRead = readBy.length > 1;

          const [{ avatar }] = returnChatterAvatar(
            loggedInUserID,
            sender,
            state.chatters
          );

          const isSenderLoggedInUser = loggedInUserID === sender;

          const senderName = state.chatters.find(
            ({ id }) => sender === id
          ).name;

          const chatterNameColour = randomColourGenerator();

          const isMessageVideo = messageType === "video";
          const isMessageImage = messageType === "image";
          const isMessageLink = messageType === "link";
          const isMessageGif = messageType === "gif";

          const starredByLoggedInUser =
            starredBy.filter((id) => id === loggedInUserID).length > 0;

          return {
            sender,
            senderName,
            chatterNameColour,
            isSenderLoggedInUser,
            sentAt,
            avatar,
            dateTag,
            content,
            messageType,
            messageStatus,
            isRead,
            starredBy,
            isMessageImage,
            isMessageLink,
            isMessageVideo,
            isMessageGif,
            starredByLoggedInUser,
            _id,
          };
        }
      );
    },

    sortMessageTypes(state) {
      state.messagesImage = state.messagesInfo
        .filter((message) => message.messageType === "image")
        .reverse();

      state.messagesLink = state.messagesInfo
        .filter((message) => message.messageType === "link")
        .reverse();
    },

    setChateeOnline(state, action) {
      const loggedInUserID = action.payload;

      let onlineStatus;
      if (!state.isGroupChat) {
        onlineStatus = state.chatters.filter(
          ({ id }) => id !== loggedInUserID
        )[0].onlineStatus;

        state.lastSeenMessage = returnLastSeenMessage(onlineStatus);
      }
    },

    setGroupChatFlag(state, action) {
      state.isGroupChat = action.payload;
    },

    filterMessagesByDate(state) {
      // TODO: check whether this can replace messagesInfo
      let dates = [];

      const messages = state.messagesInfo;

      messages.forEach(({ dateTag }) => {
        if (dates.length && !dates.includes(dateTag)) {
          dates.push(dateTag);
        } else if (!dates.length) {
          dates.push(dateTag);
        }
      });

      const messagesWithDates = new Map();

      dates.forEach((date) => {
        messagesWithDates.set(
          date,
          messages.filter(({ dateTag }) => dateTag === date)
        );
      });

      state.messagesWithDates = [...messagesWithDates];

      state.messagesAvail = true;
    },

    socketMessageSend(state, action) {
      const {
        content,
        createdAt,
        messageStatus,
        loggedInUserID,
        sender,
        messageType,
      } = action.payload;

      const sentAt = extractTime(createdAt);

      const [{ name: senderName, avatar }] = state.chatters.filter(
        ({ id }) => id === sender
      );

      const chatterNameColour = randomColourGenerator();

      const isSenderLoggedInUser = sender === loggedInUserID;

      const isRead = messageStatus === "read";

      const isMessageVideo = messageType === "video";
      const isMessageImage = messageType === "image";
      const isMessageLink = messageType === "link";
      const isMessageGif = messageType === "gif";

      const dateTag = "Today";

      const starredByLoggedInUser = false;

      const starredBy = [];

      const newMessage = {
        sender,
        senderName,
        chatterNameColour,
        isSenderLoggedInUser,
        content,
        sentAt,
        isRead,
        avatar,
        dateTag,
        messageType,
        isMessageGif,
        isMessageImage,
        isMessageLink,
        isMessageVideo,
        starredByLoggedInUser,
        starredBy,
      };

      state.messagesInfo.push(newMessage);

      const lastMessageTuppleIndex = state.messagesWithDates.length - 1;

      const isChatEmpty =
        state.messagesWithDates[lastMessageTuppleIndex][0] === undefined;

      if (!isChatEmpty) {
        const lastMessageDate =
          state.messagesWithDates[lastMessageTuppleIndex][0];
        const mesageSentToday = lastMessageDate === "Today";

        if (mesageSentToday) {
          state.messagesWithDates[lastMessageTuppleIndex][1].push(newMessage);
        }
      } else {
        const newMessageDate = ["Today", [newMessage]];
        state.messagesWithDates.push(newMessageDate);
      }
    },

    unattendedMessagesController(state, action) {
      // repeated for every message sent.
      const { status, sender, loggedInUserID } = action.payload;

      if (sender === loggedInUserID) {
        state.messagesInfo[state.messagesInfo.length - 1].messageStatus =
          status;

        const messageWithDatesLastIndex = state.messagesWithDates.length - 1;

        // eslint-disable-next-line no-unused-vars
        const [dateTag, messages] =
          state.messagesWithDates[messageWithDatesLastIndex];

        const messagesLastIndex = messages.length - 1;

        messages[messagesLastIndex].messageStatus = status;
      }
    },

    changeMessageStatus(state, action) {
      const messageStatus = action.payload;

      state.messagesInfo.forEach((message) =>
        messageStatusController(message, messageStatus)
      );

      state.messagesWithDates.forEach((tupple) =>
        tupple[1].forEach((message) =>
          messageStatusController(message, messageStatus)
        )
      );
    },

    sortMediaDates(state) {
      let monthYear = [];

      state.messagesImage.forEach((message) => {
        const extractedMonthYear = extractMonthYear(message);

        !monthYear.includes(extractedMonthYear)
          ? monthYear.push(extractedMonthYear)
          : null;
      });

      const sortedImages = new Map();

      monthYear.forEach((date) => {
        sortedImages.set(
          date,
          state.messagesImage.filter(
            (message) => date === extractMonthYear(message)
          )
        );
      });

      state.sortedImages = [...sortedImages];
    },
  },
});

export const {
  setChatters,
  setChateeOnline,
  setMessagesInfo,
  setGroupChatFlag,
  filterMessagesByDate,
  socketMessageSend,
  unattendedMessagesController,
  sortMessageTypes,
  sortMediaDates,
  changeMessageStatus,
} = sessionSlice.actions;

export default sessionSlice.reducer;
