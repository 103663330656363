import right_arrow from "../../../../Assets/right_arrow.svg";

function DetailsSettings() {
  return (
    <section className="details_settings_container box_shadow">
      <section className="display_settings mb_14">
        <div className="setting_name space_between">
          <p className="fs_14">Disappearing Messages</p>
          <img src={right_arrow} alt="" className="cursor_pointer" />
        </div>

        <div className="setting_opt">
          <span className="details_font_light">Off</span>
        </div>
      </section>

      <div className="details_line"></div>
    </section>
  );
}

export default DetailsSettings;
