import { useEffect, useState } from "react";

import "./ChatsHeader.css";
import { useSelector } from "react-redux";
import { showInfoSidebar } from "../../../helper_functions/event-handlers";

function ChatscreenHeader() {
  const lastSeenMessage = useSelector((state) => state.session.lastSeenMessage);
  const selectedChat = useSelector((state) => state.chats.selectedChat);
  const [timeOver, setTimeOver] = useState(false);

  const { isGroupChat, otherUsers } = selectedChat;

  const usersNames = otherUsers.map((user) => user.name).join(", ");

  useEffect(() => {
    const time = 3000;
    setTimeout(() => {
      setTimeOver(true);
    }, time);

    return () => setTimeOver(false);
  }, [selectedChat.id]);

  return selectedChat ? (
    <section className="right_header" onClick={() => showInfoSidebar(true)}>
      <img
        src={selectedChat.chatAvatar}
        alt="fill"
        className="cursor_pointer"
      />

      <div className="name_status cursor_pointer">
        <h5>{selectedChat.chatName}</h5>
        <p
          className={`truncate ${
            lastSeenMessage === "Online" && timeOver ? "online_color" : ""
          }`}
        >
          {isGroupChat
            ? usersNames
            : timeOver
            ? lastSeenMessage
            : "click here for contact info"}
        </p>
      </div>

      <div className="search_bar">
        <i className="fa-solid fa-magnifying-glass cursor_pointer"></i>
        <i className="fa-solid fa-ellipsis-vertical cursor_pointer"></i>
      </div>
    </section>
  ) : null;
}

export default ChatscreenHeader;
