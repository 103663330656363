import { useSelector } from "react-redux";
import Contact from "./Contact/Contact";

import "./ContactList.css";

function ContactList() {
  const filteredUsers = useSelector((state) => state.ui.filteredUsers);

  return (
    <main className="forward_contactList">
      <p className="forward_font_bold">RECENT CHATS</p>
      <ul>
        {filteredUsers.map((user) => {
          return (
            <li key={user._id}>
              <Contact user={user} />
            </li>
          );
        })}
      </ul>
    </main>
  );
}

export default ContactList;
