import { useSelector } from "react-redux";

import UserInfo from "./UserInfo/UserInfo";
import CTA from "./CTA/CTA";
import MediaPreview from "./MediaPreview/MediaPreview";
import DangerCTA from "../DangerCTA/DangerCTA";
import InfoHeader from "./InfoHeader/InfoHeader";

import TabPanel from "./Tabpanel/TabPanel";

function Contactinfo() {
  const showTabPanel = useSelector((state) => state.ui.showTabPanel);

  return (
    <section className={`third_section ${showTabPanel && "showTabPanel"}`}>
      {showTabPanel ? (
        <TabPanel />
      ) : (
        <>
          <InfoHeader />
          <UserInfo />
          <MediaPreview />
          <CTA />
          <DangerCTA />
        </>
      )}
    </section>
  );
}

export default Contactinfo;
