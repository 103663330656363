import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

function EmojiPicker(props) {
  const { show, insertEmojiHandler } = props;

  return show ? (
    <article className="details_picker absolute">
      <Picker data={data} theme="light" onEmojiSelect={insertEmojiHandler} />
    </article>
  ) : null;
}

export default EmojiPicker;
