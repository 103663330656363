import SidebarHeaderPopup from "../SidebarHeaderPopup";

import { showAddFile } from "../../../Sidebar/GroupDetails/event-handlers";

import "./AddGroupPicPopup.css";

const menuItems = [
  { item: "Take Photo", clickHandler: () => {} },
  { item: "Upload Photo", clickHandler: showAddFile },
  { item: "Emoji & Sticker", clickHandler: () => {} },
];

function AddGroupPicPopup({ show }) {
  return show ? (
    <div className="details-popup_container">
      <SidebarHeaderPopup menuItems={menuItems} />
    </div>
  ) : null;
}

export default AddGroupPicPopup;
