import { handleForwardingClick, hideTabPanel } from "./event-handlers";

// TODO:try reducing handlers(similar-ish)

import "./MediaHeader.css";

import mh_forward from "../../../../Assets/mh_forward.png";
import mh_down from "../../../../Assets/mh__.png";
import mh_delete from "../../../../Assets/mh_delete.png";
import mh_star from "../../../../Assets/mh_star.png";
import mh_back from "../../../../Assets/mh_back.png";
import { useSelector } from "react-redux";

function MediaHeader() {
  const showSidebarActions = useSelector(
    (state) => state.ui.showSidebarActions
  );

  return (
    <header className="mh_container">
      <section className="mh_icons fit_content">
        <button onClick={hideTabPanel}>
          <img src={mh_back} alt="Go back" className="fit_content" />
        </button>

        {showSidebarActions ? (
          <div className="mh_icons_right">
            <button>
              <img src={mh_star} alt="Star messages" className="fit_content" />
            </button>
            <button>
              <img
                src={mh_delete}
                alt="delete messages"
                className="fit_content"
              />
            </button>
            <button onClick={handleForwardingClick}>
              <img src={mh_forward} alt="forward messages" />
            </button>
            <button>
              <img src={mh_down} alt="down" />
            </button>
          </div>
        ) : null}
      </section>
    </header>
  );
}

export default MediaHeader;
