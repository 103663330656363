/* eslint-disable no-unused-expressions */
import { useEffect, useState, memo } from "react";
import { socketContext } from "./socket-context";
import { socketEvents } from "./socket-constants";
import { useDispatch, useSelector } from "react-redux";

import {
  onUpdateChat,
  receiveMessageSocket,
} from "../../../Store/slices/allChats-slice";

import {
  changeMessageStatus,
  socketMessageSend,
  unattendedMessagesController,
} from "../../../Store/slices/chats-slice";

function SocketContextProvider({ children }) {
  const [socket, setSocket] = useState();
  const loggedInUserID = useSelector((state) => state.userLogin.userData._id);
  const users = useSelector((state) => state.chats.users);
  const { chatID: selectedChatID } = useSelector(
    (state) => state.chats.selectedChat
  );

  const dispatch = useDispatch();

  function emitSocketEvent(event, payload) {
    socket.emit(event, payload);
  }

  useEffect(() => {
    if (socket) {
      socket.on(socketEvents.newMessage, (data) => {
        const { unreadCount, _id, latestMessage } = data;

        const { createdAt, content, messageStatus, chat, sender, messageType } =
          latestMessage;

        const commonPayload = {
          createdAt,
          content,
          messageStatus,
          loggedInUserID,
        };

        const receiveMessagePayload = {
          ...commonPayload,
          chat,
          unreadCount,
        };

        const messageSendPayload = {
          ...commonPayload,
          sender,
          messageType,
        };

        dispatch(receiveMessageSocket(receiveMessagePayload));

        _id === selectedChatID
          ? dispatch(socketMessageSend(messageSendPayload))
          : null;

        dispatch(
          unattendedMessagesController({
            status: messageStatus,
            sender,
            loggedInUserID,
          })
        );
      });

      socket.on(socketEvents.updateChat, (data) => {
        const payload = { receivedChat: data, loggedInUserID };
        dispatch(onUpdateChat(payload));

        const {
          _id: chatId,
          latestMessage: { messageStatus },
        } = data;

        chatId === selectedChatID
          ? dispatch(changeMessageStatus(messageStatus))
          : null;
      });

      socket.on(socketEvents.userStatusChanged, ({ onlineStatus, _id }) => {});

      socket.on(socketEvents.messageStatusChanged, (data) => {});
    }

    return () => {
      if (socket) {
        socket.off();
      }
    };
  }, [socket, dispatch, loggedInUserID, users, selectedChatID]);

  const socketValueProps = { socket, setSocket, emitSocketEvent };

  return (
    <socketContext.Provider value={socketValueProps}>
      {children}
    </socketContext.Provider>
  );
}

export default memo(SocketContextProvider);
