import { createSlice } from "@reduxjs/toolkit";

const INITIAL_MESSAGE_STATE = {
  typedMessage: "",
  isShiftPressed: false,
  messageContainsText: false,
};

const messagesSlice = createSlice({
  initialState: INITIAL_MESSAGE_STATE,
  name: "messages",
  reducers: {
    handleEnterDown(state, action) {
      const keyPressed = action.payload;

      const { typedMessage, isShiftPressed } = state;

      if (keyPressed === "Enter" && typedMessage && isShiftPressed) {
      } else if (
        keyPressed === "Enter" &&
        typedMessage.trim().length &&
        !isShiftPressed
      ) {
        state.typedMessage = "";
      }
    },

    resetTypedMessage(state) {
      state.typedMessage = INITIAL_MESSAGE_STATE.typedMessage;
    },

    handleShiftUp(state, action) {
      const keyUp = action.payload;

      if (keyUp === "Shift") {
        state.isShiftPressed = false;
      }
    },

    handleChatInputChange(state, action) {
      state.typedMessage = action.payload;
    },

    handleShiftDown(state, action) {
      const keyPressed = action.payload;

      if (keyPressed === "Shift") {
        state.isShiftPressed = true;
      }
    },

    messageChecker(state) {
      if (state.typedMessage.trim().length > 0) {
        state.messageContainsText = true;
      } else {
        state.messageContainsText = false;
      }
    },

    addEmoji(state, action) {
      state.typedMessage += action.payload;
    },
  },
});

export const {
  handleEnterDown,
  handleChatInputChange,
  handleShiftDown,
  handleShiftUp,
  messageChecker,
  resetTypedMessage,
  addEmoji,
} = messagesSlice.actions;

export default messagesSlice.reducer;
