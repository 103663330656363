import { useEffect, useRef } from "react";
import ChatBubble from "../../ChatBubbles/ChatBubble";
import "./ChatBody.css";

import { useSelector } from "react-redux";

function ChatBody() {
  const messagesWithDates = useSelector(
    (state) => state.session.messagesWithDates
  );

  const displayInfoSidebar = useSelector(
    (state) => state.ui.displayInfoSidebar
  );

  const chatContainerRef = useRef();

  useEffect(() => {
    let domeNode = chatContainerRef.current;
    // eslint-disable-next-line no-unused-expressions
    domeNode ? (domeNode.scrollTop = domeNode.scrollHeight) : null;
  }, [messagesWithDates]);

  return (
    <div
      className={`chat_container ${displayInfoSidebar && "reduce_padding"}`}
      ref={chatContainerRef}
    >
      {messagesWithDates
        ? messagesWithDates.map(([dateTag, messagesDateTag]) => (
            <article key={Math.random()}>
              <ChatBubble dateTag={dateTag} messagesDateTag={messagesDateTag} />
            </article>
          ))
        : null}
    </div>
  );
}

export default ChatBody;
