import { useSelector } from "react-redux";
import { useContext, useEffect } from "react";

import { io } from "socket.io-client";
import { socketContext } from "../Contexts/socket-context/socket-context";

import UserLogin from "../UserLogin/UserLogin";

import "./App.css";

import { baseSocketURL } from "../../constants/api-endpoints";

import Chatscreen from "../Chatscreen/Chatscreen";
import ForwardMessage from "../Modals/ForwardMessage/ForwardMessage";
import Backdrop from "../Backdrop/Backdrop";

function App() {
  const isLoggedIn = useSelector((state) => state.userLogin.isLoggedIn);
  const payload = useSelector((state) => state.userLogin.userData);

  const { setSocket } = useContext(socketContext);

  useEffect(() => {
    if (isLoggedIn) {
      const socket = io(baseSocketURL, { query: payload });

      socket.on("connect", () => {
        setSocket(socket);
      });
    }
  }, [setSocket, isLoggedIn, payload]);

  return (
    <>
      {!isLoggedIn ? <UserLogin /> : null}

      {isLoggedIn ? <Chatscreen /> : null}

      <Backdrop>
        <ForwardMessage />
      </Backdrop>
    </>
  );
}

export default App;
