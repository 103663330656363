import { useSelector } from "react-redux";

import ErrorText from "./ErrorText/ErrorText";
import LoadingLogin from "./LoadingLoginPage/LoadingLogin";

import {
  formSubmitHandler,
  inputChangeHandler,
} from "../../helper_functions/event-handlers";

import { EMAIL, PASSWORD } from "../../constants/redux";

import "./UserLogin.css";

function UserLogin() {
  const {
    showLoader,
    showErrors: { email: emailError, password: passwordError },
    values: { email: typedEmail, password: typedPassword },
  } = useSelector((state) => state.userLogin);

  return (
    <div className="screen_container">
      <div className={`login_main ${showLoader ? "blur" : ""}`}>
        <div className="login_main_header"></div>
        <div className="login_whatsapp_box">
          <h3>Chat Concept</h3>
          <div className="login_main_container">
            <div className="login_box">
              <h4>Login to Chat Concept</h4>
              <div className="login_details">
                <form onSubmit={formSubmitHandler}>
                  <input
                    type={EMAIL}
                    name={EMAIL}
                    className={`inputs ${emailError ? "invalidInputs" : ""}`}
                    placeholder="Email Address"
                    onChange={inputChangeHandler}
                    value={typedEmail}
                  />

                  <ErrorText error={emailError} />

                  <input
                    type={PASSWORD}
                    name={PASSWORD}
                    className={`inputs ${passwordError ? "invalidInputs" : ""}`}
                    placeholder="Password"
                    onChange={inputChangeHandler}
                    value={typedPassword}
                  />

                  <ErrorText error={passwordError} />

                  <button id="login" type="submit">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLoader && <LoadingLogin />}
    </div>
  );
}

export default UserLogin;
