export default function DangerUser({ chatName }) {
  return (
    <section className="danger_styles danger_bg_color_1">
      <div className="danger_cta">
        <i className="fa-solid fa-ban"></i>
        <h5>Block {chatName}</h5>
      </div>
      <div className="danger_cta">
        <i className="fa-solid fa-thumbs-up fa-rotate-90"></i>
        <h5>Report {chatName}</h5>
      </div>
      <div className="danger_cta">
        <i className="fa-solid fa-trash"></i>
        <h5>Delete {chatName}</h5>
      </div>
    </section>
  );
}
