import HeaderPopupListButton from "./HeaderPopupListButton/HeaderPopupListButton";
import "./SidebarHeaderPopup.css";

function SidebarHeaderPopup({ menuItems }) {
  return (
    <nav className="hp_navbar">
      <ul className="hp_nav_list">
        {menuItems.map((item) => (
          <li className="hp_nav_li" key={Math.random()}>
            <HeaderPopupListButton item={item} />
          </li>
        ))}
      </ul>
    </nav>
  );
}
export default SidebarHeaderPopup;
