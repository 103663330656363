import { useSelector } from "react-redux";

import MediaThumbnail from "../MediaThumbnail/MediaThumbnail";

import "./Media.css";

function Media() {
  const sortedImages = useSelector((state) => state.session.sortedImages);

  return (
    <div className="scroll">
      {sortedImages
        ? sortedImages.map((tupple) => {
            // index 1 of tupple holds msg objs.
            const [one, two, three] = tupple[1];
            const firstThree = [one, two, three];

            // index 0 holds date
            const date = tupple[0];

            return (
              <div key={date} className="media_container">
                <div className="media_date">
                  <h5>{date}</h5>
                </div>

                <div className="media_imgs">
                  {firstThree.map((message) =>
                    message ? (
                      <article key={Math.random()}>
                        <MediaThumbnail message={message} />
                      </article>
                    ) : null
                  )}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}

export default Media;
